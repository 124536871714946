import React from 'react';
import {ProgressCard} from '../components/ProgressCard/ProgressCard';
import {ReactComponent as CurrentLessonIcon} from 'shared/assets/icons/CurrentLessonIcon.svg';
import {CurriculumProgress, QueryResult} from '../types';
import {LessonContent} from './Lesson.content';

type Props = {
  profileName: string;
  curriculumProgress: QueryResult<CurriculumProgress>;
};

export const Lesson: React.FC<Props> = ({profileName, curriculumProgress}) => {
  return (
    <ProgressCard
      title={`${profileName}'s Current Lesson`}
      icon={<CurrentLessonIcon />}>
      <LessonContent
        curriculumProgress={curriculumProgress}
        profileName={profileName}
      />
    </ProgressCard>
  );
};
