import React from 'react';
import {Box, Button} from '@mui/material';
import {Link} from 'react-router-dom';
import {turquoise900} from 'shared/styles/palette';

type Props = {
  to: string;
};

export const LinkButton: React.FC<Props> = ({to, children}) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      paddingTop: '16px',
    }}>
    <Button
      component={Link}
      to={to}
      sx={{
        color: turquoise900,
        textAlign: 'center',
        fontSize: '16px',
        fontWeight: 700,
        lineHeight: '1.6',
        letterSpacing: '-0.048px',
        textTransform: 'none',
      }}>
      {children}
    </Button>
  </Box>
);
