import React from 'react';
import Typography from '@mui/material/Typography';
import useStyles from './ReferralLink.styles';
import {resolveQueryStrings} from 'shared/utils/stringUtils/stringUtils';
import {DEFAULT_LOCALE} from 'shared/utils/localization';

type Props = {
  text?: string;
};

const BodyText = ({text}: Props) => {
  const classes = useStyles();
  const [bodyText] = resolveQueryStrings([text], DEFAULT_LOCALE);

  return (
    <Typography my="20px" className={classes.bodyText}>
      {bodyText || (
        <>
          Invite friends & family and you’ll both receive{' '}
          <span className={classes.linkHighlightText}>one free month</span> of
          Ello.
        </>
      )}
    </Typography>
  );
};

export default BodyText;
