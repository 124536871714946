import React from 'react';
import {ProgressCard} from '../components/ProgressCard/ProgressCard';
import {ReactComponent as CurrentLessonIcon} from 'shared/assets/icons/CurrentLessonIcon.svg';
import {SkeletonRows} from '../components/SkeletonRows';

type Props = {
  profileName: string;
};

export const LessonEmpty: React.FC<Props> = ({profileName}) => {
  return (
    <ProgressCard
      title={`${profileName}'s Current Lesson`}
      icon={<CurrentLessonIcon />}
      isEmpty>
      <SkeletonRows count={2} />
    </ProgressCard>
  );
};
