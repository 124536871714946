import Box from '@mui/material/Box';
import React, {FC} from 'react';
import {trackEventWithIp} from 'shared/utils/analytics';
import {Product} from '../Product.types';
import InitialChoices from './InitialChoices';
import UpdatedChoices from './UpdatedChoices';
import UpgradeChoices from './UpgradeChoices';

type props = {
  choices: Product[];
  onNext: (step?: number) => void;
  onValidationChanged: (isValid: boolean) => void;
  selectedProduct: string;
  setSelectedProduct: (priceId: string) => void;
  useUpdated: boolean;
  useUpgrade: boolean;
};

const Choices: FC<props> = ({
  choices,
  onNext,
  onValidationChanged,
  selectedProduct,
  setSelectedProduct,
  useUpdated,
  useUpgrade,
}) => {
  const onSelectProduct = (priceId: string, step?: number) => {
    if (priceId) {
      setSelectedProduct(priceId);
      trackEventWithIp('Onboarding SelectProduct', {
        priceId,
        title: choices?.find(choice => choice.priceId === priceId)?.title,
      });
    }

    onValidationChanged(true);
    onNext(step);
  };

  return (
    <Box>
      {(() => {
        if (useUpgrade) {
          return (
            <UpgradeChoices
              choices={choices}
              onSelectProduct={onSelectProduct}
              selectedProduct={selectedProduct}
            />
          );
        }
        if (useUpdated) {
          return (
            <UpdatedChoices
              choices={choices}
              onSelectProduct={onSelectProduct}
              selectedProduct={selectedProduct}
            />
          );
        }
        return (
          <InitialChoices
            choices={choices}
            onSelectProduct={onSelectProduct}
            selectedProduct={selectedProduct}
          />
        );
      })()}
    </Box>
  );
};

export default Choices;
