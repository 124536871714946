/**
 * Like GenericSubmitPanel, this is an onboarding flow submission panel, but this
 * one handles in app submissions.
 */

import {withApollo} from '@apollo/client/react/hoc';
import Alert from '@mui/material/Alert';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import withStyles from '@mui/styles/withStyles';
import React from 'react';
import {trackEventWithIp} from 'shared/utils/analytics';
import {LocalizationContext} from 'shared/utils/localization';
import {collectQueryParams} from 'shared/utils/queryParams';
import {PARTIAL_ONBOARDING_MUTATION} from '../GenericSubmitPanel/GenericSubmitPanel.query';
import {VALIDATE_ONBOARDING_DATA} from './InAppSubmitPanel.query';
import styles from './InAppSubmitPanel.styles';

class InAppSubmitPanel extends React.Component {
  state = {
    loading: false,
    error: null,
    errorVisible: false,
  };

  handlePostOnboard = (params, closeData) => {
    const {onboardingResponseUid, ...content} = params;

    try {
      this.props.client.mutate({
        mutation: PARTIAL_ONBOARDING_MUTATION,
        variables: {
          content,
          flowName: this.props.flowName,
          onboardingResponseUid,
        },
      });
      const href = 'uniwebview://event?eventType=webView&data=' + closeData;
      window.location.href = href;
    } catch {
      this.setState({
        loading: false,
        error: 'An error occured. Text us at (415) 214-8119.',
        errorVisible: true,
      });
    }
  };

  performAction = () => {
    this.setState({loading: true});

    const params = collectQueryParams();

    switch (this.props.action) {
      case 'onboard':
        trackEventWithIp('InAppOnboarding FinalValidation');

        const {email, phone, name, countryCode} = params;
        if (!email) {
          this.setState({error: 'Email is required', errorVisible: true});
          return;
        }

        if (!phone) {
          this.setState({
            error: 'Phone number is required',
            errorVisible: true,
          });
          return;
        }

        const variables = {
          name,
          email,
          phoneNumber: phone,
          isInternationalCustomer: !!countryCode,
        };
        this.props.client
          .mutate({
            mutation: VALIDATE_ONBOARDING_DATA,
            variables,
          })
          .then(() => {
            params.locale = this.locale;
            trackEventWithIp('InAppOnboarding PaywallInitiate');
            const newUrl =
              'uniwebview://event?eventType=paywall&data=show&' +
              new URLSearchParams(params).toString();
            window.location.href = newUrl;
          })
          .catch(error => {
            trackEventWithIp('InAppOnboarding ValidationIssue');
            this.setState({loading: false});
            this.setState({
              error: `Could not validate details: ${error.message}`,
              errorVisible: true,
            });
          });
        break;
      case 'postOnboard':
        trackEventWithIp('InAppOnboarding PostOnboardSuccess');
        this.handlePostOnboard(params, 'showNextScreen');
        break;
      default:
        trackEventWithIp('InAppOnboarding Close');
        this.handlePostOnboard(params, 'close');
    }

    return;
  };

  handleErrorClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({errorVisible: false});
  };

  handleClose = () => {
    this.setState({loading: false});
  };

  render() {
    const {classes} = this.props;

    return (
      <LocalizationContext.Consumer>
        {({locale}) => {
          this.locale = locale;
          return (
            <>
              <Backdrop
                className={classes.backdrop}
                open={this.state.loading}
                onClick={this.handleClose}>
                <CircularProgress color="primary" />
              </Backdrop>

              <Snackbar
                open={this.state.errorVisible}
                onClose={this.handleErrorClose}>
                <Alert onClose={this.handleErrorClose} severity="error">
                  {this.state.error}
                </Alert>
              </Snackbar>
            </>
          );
        }}
      </LocalizationContext.Consumer>
    );
  }
}

export default withStyles(styles)(
  withApollo(InAppSubmitPanel, {withRef: true}),
);
