import React from 'react';
import {Grid, Stack} from '@mui/material';
import {Lesson} from '../Lesson/Lesson';
import {ReadingLevel} from '../ReadingLevel/ReadingLevel';
import {ReadingRecord} from '../ReadingRecord/ReadingRecord';
import {ReadingStats} from '../ReadingStats/ReadingStats';
import {TrickyWords} from '../TrickyWords/TrickyWords';
import {
  CurriculumProgress,
  QueryResult,
  ReadingRecordsList,
  WeeklyAchievement,
} from '../types';

type Props = {
  profileName: string;
  profileId: string;
  weeklyAchievements: QueryResult<WeeklyAchievement>;
  readingRecords: QueryResult<ReadingRecordsList[]>;
  curriculumProgress: QueryResult<CurriculumProgress>;
};

export const DesktopDashboard: React.FC<Props> = ({
  profileName,
  profileId,
  curriculumProgress,
  readingRecords,
  weeklyAchievements,
}) => (
  <Grid container spacing={2}>
    <Grid item md={6}>
      <Stack
        sx={{
          alignItems: 'center',
          gap: '16px',
        }}>
        <ReadingLevel
          profileName={profileName}
          curriculumProgress={curriculumProgress}
        />
        <ReadingRecord
          profileName={profileName}
          profileId={profileId}
          readingRecords={readingRecords}
        />
      </Stack>
    </Grid>

    <Grid item md={6}>
      <Stack
        sx={{
          alignItems: 'center',
          gap: '16px',
        }}>
        <ReadingStats
          profileName={profileName}
          weeklyAchievements={weeklyAchievements}
        />
        <TrickyWords
          profileName={profileName}
          weeklyAchievements={weeklyAchievements}
        />
        <Lesson
          profileName={profileName}
          curriculumProgress={curriculumProgress}
        />
      </Stack>
    </Grid>
  </Grid>
);
