import React from 'react';
import {Box, ListItem} from '@mui/material';
import {ReactComponent as CheckIcon} from 'shared/assets/icons/CheckIcon.svg';
import {ReactComponent as BookmarkIcon} from 'shared/assets/icons/BookmarkIcon.svg';
import {gray700, teal500} from 'shared/styles/palette';
import {PrimaryTypography, SecondaryTypography} from './Typography';
import {BookStatus} from '../types';

type Props = {
  bookTitle: string;
  status: BookStatus;
  duration: string;
  date: string;
  image?: string;
};

const ReadingRecordItem: React.FC<Props> = ({
  bookTitle,
  status,
  duration,
  date,
  image,
}) => (
  <ListItem
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: 0,
    }}>
    <Box sx={{display: 'flex', gap: '12px', alignItems: 'center'}}>
      <Box
        component="img"
        src={image}
        alt={bookTitle}
        sx={{
          flexShrink: 0,
          width: '64px',
          height: '64px',
        }}
      />
      <Box sx={{display: 'flex', flexDirection: 'column'}}>
        <Box sx={{display: 'flex', gap: '8px', alignItems: 'center'}}>
          {status === BookStatus.Read && <CheckIcon />}
          {status === BookStatus.Started && <BookmarkIcon />}
          <PrimaryTypography
            sx={{
              color: status === BookStatus.Started ? gray700 : teal500,
            }}>
            {status === BookStatus.Read ? 'Book Read' : 'Book Started'}
          </PrimaryTypography>
        </Box>
        <SecondaryTypography>{bookTitle}</SecondaryTypography>
      </Box>
    </Box>

    <Box
      display="flex"
      flexDirection="column"
      alignItems="flex-end"
      flexShrink={0}>
      <PrimaryTypography
        sx={{
          textAlign: 'right',
          color: status === BookStatus.Started ? gray700 : teal500,
        }}>
        {duration}
      </PrimaryTypography>
      <SecondaryTypography sx={{textAlign: 'right'}}>
        {date}
      </SecondaryTypography>
    </Box>
  </ListItem>
);

export default ReadingRecordItem;
