import {ApolloError} from '@apollo/client';

export enum BookStatus {
  Read = 'Book Read',
  Started = 'Book Started',
}

export type QueryResult<T> = {
  data: T | null;
  error: ApolloError | undefined;
  loading: boolean;
};

export type LifeTimeAchievementsReport = {
  books: number;
  minutes: number;
  words: number;
};

export type WeeklyAchievement = {
  books: number;
  gradeRole?: string;
  minutes: number;
  trickyWords: string[];
  words: number;
};

export type ReadingRecordsList = {
  bookTitle: string;
  event: string;
  minutes: number | null;
  timestamp: string;
  coverPhoto?: string;
};

export type CurriculumProgress = {
  currentLessonTitle: string;
  gradeAndMonth: string;
  gradeName: string;
  gradeRole: string;
  lessonDescription: string;
  monthEquivalent: string;
  nextSubGrade: string;
  progress: number;
  remainingQuests: number;
  subGrade: string;
};

export type Quest = {
  id: string;
};

export enum ProfileCharacter {
  Bull = 'bull',
  Cat = 'cat',
  Robot = 'robot',
  DinoBird = 'dinoBird',
  Bird = 'bird',
  Unicorn = 'unicorn',
}
