import featureFlags, {FEATURE_IDS} from 'shared/utils/featureFlags';
import {
  Customer,
  isInAppCustomer,
  isUSCustomer,
} from '../../../shared/types/customer';

function showReferralLink(customer?: Customer) {
  const isUsUser = isUSCustomer(
    customer?.userDetails.countryCode,
    customer?.userDetails.phoneNumber,
  );

  if (!isUsUser) {
    return false;
  }

  const isFFOn = featureFlags.isOn(FEATURE_IDS.SHOW_REFERRAL_CODE);
  const hasReferralCode = !!customer?.referralCode;
  const inAppCustomer = isInAppCustomer(customer?.billingSource);
  const isInAppFFOn = featureFlags.isOn(FEATURE_IDS.ENABLE_IN_APP_REFERRALS);

  if (!isFFOn || !hasReferralCode) {
    return false;
  }

  if (inAppCustomer) {
    return isInAppFFOn;
  }

  return true;
}

const AccountSettingsService = {
  showReferralLink,
};

export default AccountSettingsService;
