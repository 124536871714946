const getIpAddress = async () => {
  try {
    const response = await fetch('https://api.ipify.org?format=json');
    const data = await response.json();
    return data.ip;
  } catch (error) {
    console.error('Error fetching IP address:', error);
    return null;
  }
};

export const trackEventWithIp = async (eventName, properties = {}) => {
  const ipAddress = await getIpAddress();

  window.analytics.track(eventName, properties, {
    context: {
      ip: ipAddress,
    },
  });
};

export const trackPageWithIp = async (pageName, properties = {}) => {
  const ipAddress = await getIpAddress();

  window.analytics.page(pageName, properties, {
    context: {
      ip: ipAddress,
    },
  });
};
