import React from 'react';
import {SxProps, Theme, Typography} from '@mui/material';
import {blue500} from 'shared/styles/palette';

type Props = {
  sx?: SxProps<Theme>;
};

export const PrimaryTypography: React.FC<Props> = ({sx, children}) => (
  <Typography
    variant="h6"
    sx={{
      fontSize: '44px',
      fontWeight: '800',
      lineHeight: '1.3',
      letterSpacing: '-0.352px',
      color: blue500,
      ...sx,
    }}>
    {children}
  </Typography>
);
