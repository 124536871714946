import React from 'react';
import {Box, Skeleton} from '@mui/material';
import {gray100} from 'shared/styles/palette';

type Props = {
  count?: number;
};

export const SkeletonRows: React.FC<Props> = ({count = 1}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        width: '100%',
      }}>
      {Array.from({length: count}).map((_, index) => (
        <Skeleton
          key={index}
          variant="rectangular"
          animation={false}
          height="26px"
          sx={{background: gray100}}
        />
      ))}
    </Box>
  );
};
