import React from 'react';
import LinkContainer from './ReferralLink.link-container';
import {ReferralLinkService} from './ReferralLink.service';
import Box from '@mui/material/Box';

type Props = {
  linkHeader?: string;
  linkBody?: string;
  baseURL?: string;
  imageURL?: string;
  shareMessage?: string;
};

const CopyReferralLink = ({
  baseURL,
  linkHeader,
  linkBody,
  imageURL,
  shareMessage,
}: Props) => {
  const referralLink = ReferralLinkService.referralLink(baseURL);

  return (
    <Box>
      {referralLink && (
        <LinkContainer
          header={linkHeader}
          body={linkBody}
          referralLink={referralLink}
          imageURL={imageURL}
          shareMessage={shareMessage}
        />
      )}
    </Box>
  );
};

export default CopyReferralLink;
