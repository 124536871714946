import {Typography} from '@mui/material';
import React from 'react';
import {blue500} from 'shared/styles/palette';

export const LessonTitle: React.FC = ({children}) => {
  return (
    <Typography
      sx={{
        fontSize: '26px',
        fontWeight: 800,
        color: blue500,
        textAlign: 'center',
        lineHeight: '1.4',
        letterSpacing: '-0.104px',
        mb: '12px',
      }}>
      {children}
    </Typography>
  );
};
