import React from 'react';
import {SxProps, Theme, Typography} from '@mui/material';
import {gray900} from 'shared/styles/palette';

type Props = {
  sx?: SxProps<Theme>;
};

export const CardTitle: React.FC<Props> = ({children, sx}) => {
  return (
    <Typography
      variant="h6"
      sx={{
        fontSize: '22px',
        fontWeight: '700',
        lineHeight: '1.6',
        color: gray900,
        ...sx,
      }}>
      {children}
    </Typography>
  );
};
