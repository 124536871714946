import React from 'react';
import {ReactComponent as RecentBooksIcon} from 'shared/assets/icons/RecentBooksIcon.svg';
import {ProgressCard} from '../components/ProgressCard/ProgressCard';
import ReadingRecordList from './ReadingRecordList';
import {QueryResult, ReadingRecordsList} from '../types';
import {Loader} from '../components/Loader';

type Props = {
  profileName: string;
  profileId: string;
  readingRecords: QueryResult<ReadingRecordsList[]>;
};

export const ReadingRecord: React.FC<Props> = ({
  profileName,
  profileId,
  readingRecords,
}) => {
  const {data, error, loading} = readingRecords;

  const content = (() => {
    if (loading) return <Loader />;
    if (error) return null;
    return (
      <ReadingRecordList
        profileName={profileName}
        profileId={profileId}
        readingRecords={data}
      />
    );
  })();

  return (
    <ProgressCard icon={<RecentBooksIcon />} title="Reading Record">
      {content}
    </ProgressCard>
  );
};
