import React from 'react';
import {Box, Link, Typography} from '@mui/material';
import {supportEmail} from 'customerPortal/constants/constants';
import {gray700, turquoise900} from 'shared/styles/palette';

export const SupportContact = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}>
      <Box
        sx={{padding: '24px 32px 0px 32px', maxWidth: '375px', width: '100%'}}>
        <Typography
          variant="body2"
          sx={{
            color: gray700,
            textAlign: 'center',
            lineHeight: '1.6',
            letterSpacing: '-0.112px',
          }}>
          💬 What else do you want to see in your child's progress report? Email{' '}
          <Link
            href="mailto:support@ello.com"
            sx={{
              color: turquoise900,
              fontWeight: '700',
              textDecoration: 'none',
            }}>
            {supportEmail}
          </Link>
        </Typography>
      </Box>
    </Box>
  );
};
