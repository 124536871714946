import gql from 'graphql-tag';

export const QUERY_ACCOUNT_DETAILS = gql`
  query Customer {
    customer {
      id
      productType
      subscriptionPeriodEnd
      subscriptionStatus
      billingSource
      tags
      referralCode
      userDetails {
        name
        email
        phoneNumber
        countryCode
      }
      profiles {
        name
      }
    }
  }
`;
