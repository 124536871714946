export function randomiseChoices(choices: any[], randomise: boolean) {
  if (!randomise) {
    return choices;
  }

  const randomisedChoices = [...choices];

  for (let i = randomisedChoices.length - 1; i > 0; i--) {
    const randomIndex = Math.floor(Math.random() * (i + 1));

    const temp = randomisedChoices[i];
    randomisedChoices[i] = randomisedChoices[randomIndex];
    randomisedChoices[randomIndex] = temp;
  }

  return randomisedChoices;
}
