import React from 'react';
import {OnboardingFlowType} from '../../types/onboardingFlow.type';
import LanguagePicker from '../common/LanguagePicker';
import FormTextField from '../common/FormTextField';
import FormPicker from '../common/FormPicker';
import FormSlider from '../common/FormSlider';
import FormDropdown from '../common/FormDropdown';
import LocationPicker from '../common/LocationPicker';
import ProductSelectionPanel from '../ProductSelectionPanel';
import PaymentPanel from '../PaymentPanel';
import ExampleBooksPanel from '../ExampleBooksPanel';
import CheckmarkList from '../common/CheckmarkList';
import DownloadPrompt from '../DownloadPrompt/DownloadPrompt';
import GenericSubmitPanel from '../GenericSubmitPanel';
import APIValidationActionPanel from '../APIValidationActionPanel';
import LandingPage from '../LandingPage/LandingPage';
import GenericConfirmPanel from '../GenericConfirmPanel';
import InAppSubmitPanel from '../InAppSubmitPanel/InAppSubmitPanel';
import ShareableLink from '../ShareableLink/ShareableLink';
import TrialSelectionPanel from '../TrialSelectionPanel/TrialSelectionPanel';
import ReferralLink from '../ReferralLink/ReferralLink';
import CopyReferralLink from '../ReferralLink/CopyReferralLink';
import DownloadInstructions from '../ReferralLink/DownloadInstructions';
import {InternationalPhone} from '../InternationalPhone/InternationalPhone';

type Props = {
  question: any;
  idx: any;
  flowName: string;
  activeStep: any;
  actionPanel: any;
  flowSpecSteps: any;
  handleConfirm: () => void;
  handleNext: () => void;
  goToNextStep: () => void;
  handleValidation: (idx: number, isValidated: boolean) => void;
  onFocusInput: () => void;
  onBlurInput: () => void;
};

const QuestionTypeField = ({
  question,
  idx,
  flowName,
  actionPanel,
  flowSpecSteps,
  activeStep,
  handleConfirm,
  handleNext,
  goToNextStep,
  handleValidation,
  onFocusInput,
  onBlurInput,
}: Props) => {
  const questionProps = {
    ...question,
    // Note that some components such as select-type formpicker auto-advances on selection. If it's on the last step, selection may trigger
    // performAction() special action (e.g. calling updateOnboarding). User then clicks finish/submit, which calls updateOnboarding twice.
    // If using a select-type on the last page, we probably want to specify a custom redirect path.
    onNext: handleNext,
    goToNextStep: goToNextStep,
    onValidationChanged: (isValidated: any) => {
      // Unless question is explicitly marked as not required, keep track of validation status.
      if (question.required !== false) handleValidation(idx, isValidated);
    },
    flowName,
  };

  const endpoint = flowSpecSteps[activeStep].endpoint;

  switch (question.type) {
    case OnboardingFlowType.language:
      return <LanguagePicker />;
    case OnboardingFlowType.name:
      return (
        <FormTextField
          type="name"
          field={question.field}
          onFocus={onFocusInput}
          onBlur={onBlurInput}
          {...questionProps}
        />
      );
    case OnboardingFlowType.number:
      return (
        <FormTextField
          type="number"
          field={question.field}
          onFocus={onFocusInput}
          onBlur={onBlurInput}
          {...questionProps}
        />
      );
    case OnboardingFlowType.intlPhone:
      return <InternationalPhone {...questionProps} />;
    case OnboardingFlowType.phone:
      return (
        <FormTextField
          type="phone"
          field={question.field}
          onFocus={onFocusInput}
          onBlur={onBlurInput}
          {...questionProps}
        />
      );
    case OnboardingFlowType.email:
      return (
        <FormTextField
          type="email"
          field={question.field}
          onFocus={onFocusInput}
          onBlur={onBlurInput}
          {...questionProps}
        />
      );
    case OnboardingFlowType.emailSubmit:
      return (
        <FormTextField
          field={question.field}
          onFocus={onFocusInput}
          onBlur={onBlurInput}
          {...questionProps}
          type="email"
        />
      );
    case OnboardingFlowType.text:
      return (
        <FormTextField
          type="text"
          field={question.field}
          label={question.label}
          onFocus={onFocusInput}
          onBlur={onBlurInput}
          {...questionProps}
        />
      );
    case OnboardingFlowType.date:
      return (
        <FormTextField
          type="date"
          field={question.field}
          onFocus={onFocusInput}
          onBlur={onBlurInput}
          {...questionProps}
        />
      );
    case OnboardingFlowType.multiple:
      return (
        <FormPicker
          type="multiple"
          field={question.field}
          renderOther={question.renderOther}
          onFocus={onFocusInput}
          onBlur={onBlurInput}
          {...questionProps}
        />
      );
    case OnboardingFlowType.select:
      return (
        <FormPicker
          type="select"
          field={question.field}
          choices={question.choices}
          {...questionProps}
        />
      );
    case OnboardingFlowType.selectUpdated:
      return (
        <FormPicker
          selectUpdated={true}
          field={question.field}
          choices={question.choices}
          {...questionProps}
          type="select"
        />
      );
    case OnboardingFlowType.slider:
      return (
        <FormSlider
          max={question.max}
          min={question.min}
          marks={question.marks}
          field={question.field}
          defaultValue={question.defaultValue}
          {...questionProps}
        />
      );
    case OnboardingFlowType.dropdown:
      return (
        <FormDropdown
          onFocus={onFocusInput}
          onBlur={onBlurInput}
          choices={question.choices}
          field={question.field}
          {...questionProps}
        />
      );
    case OnboardingFlowType.location:
      return (
        <LocationPicker
          {...questionProps}
          onFocus={onFocusInput}
          onBlur={onBlurInput}
        />
      );
    case OnboardingFlowType.package:
      return (
        <ProductSelectionPanel
          onNext={handleNext}
          choices={question.choices}
          {...questionProps}
        />
      );
    case OnboardingFlowType.trialPlans:
      return (
        <TrialSelectionPanel
          details={question.details}
          onNext={handleNext}
          {...questionProps}
        />
      );
    case OnboardingFlowType.payment:
      return (
        <PaymentPanel
          ref={actionPanel}
          productDetails={question.productDetails}
          stripePriceId={question.stripePriceId}
          onConfirm={handleConfirm}
          {...questionProps}
        />
      );
    case OnboardingFlowType.exampleBooks:
      return <ExampleBooksPanel onNext={handleNext} {...questionProps} />;
    case OnboardingFlowType.checkmarks:
      return <CheckmarkList bullets={question.bullets} {...questionProps} />;
    case OnboardingFlowType.validate:
      return (
        <APIValidationActionPanel
          ref={actionPanel}
          endpoint={endpoint}
          {...questionProps}
        />
      );
    case OnboardingFlowType.submit:
      return (
        <GenericSubmitPanel
          ref={actionPanel}
          onConfirm={handleConfirm}
          redirectPath={question.redirectPath}
          endpoint={endpoint}
          {...questionProps}
        />
      );
    case OnboardingFlowType.survey:
      return <GenericConfirmPanel ref={actionPanel} {...questionProps} />;
    case OnboardingFlowType.downloadPrompt:
      return (
        <DownloadPrompt
          digitalLink={question.digitalLink}
          physicalLink={question.physicalLink}
          title={question.promptTitle}
          physicalBody={question.promptBodyPhysical}
          digitalBody={question.promptBodyDigital}
        />
      );
    case OnboardingFlowType.landingPage:
      return (
        <LandingPage
          textBody={question.textBody}
          content={question.content}
          buttonText={question.buttonText}
          {...questionProps}
        />
      );
    case OnboardingFlowType.inAppSubmit:
      return <InAppSubmitPanel ref={actionPanel} {...questionProps} />;
    case OnboardingFlowType.shareableLink:
      return (
        <ShareableLink
          link={question.link}
          image={question.shareImage}
          header={question.header}
          shareDialogText={question.shareDialogText}
          copyLinkText={question.copyLinkText}
          shareLinkText={question.shareLinkText}
        />
      );
    case OnboardingFlowType.referralLink:
      return (
        <ReferralLink
          header={question.header}
          qrCodeHeader={question.qrCodeHeader}
          qrCodeBody={question.qrCodeBody}
          linkHeader={question.linkHeader}
          linkBody={question.linkBody}
          baseURL={question.baseURL}
          imageURL={question.imageURL}
          shareMessage={question.shareMessage}
          imageMaxWidth={question.imageMaxWidth}
        />
      );
    case OnboardingFlowType.copyReferralLink:
      return (
        <CopyReferralLink
          linkHeader={question.linkHeader}
          linkBody={question.linkBody}
          baseURL={question.baseURL}
          imageURL={question.imageURL}
          shareMessage={question.shareMessage}
          imageMaxWidth={question.imageMaxWidth}
        />
      );
    case OnboardingFlowType.downloadInstructions:
      return (
        <DownloadInstructions
          qrCodeHeader={question.qrCodeHeader}
          qrCodeBody={question.qrCodeBody}
        />
      );
    default:
      return null;
  }
};

export default QuestionTypeField;
