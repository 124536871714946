import React from 'react';
import {ReactComponent as LifetimeIcon} from 'shared/assets/icons/LifetimeIcon.svg';
import {Box} from '@mui/material';
import {CardTitle} from '../components/ProgressCard/CardTitle';
import {Stats} from '../components/Stats';
import {Loader} from '../components/Loader';
import {LifeTimeAchievementsReport, QueryResult} from '../types';
import {formatNumber} from '../ProgressReportScreen.service';

type Props = {
  lifeTimeAchievements: QueryResult<LifeTimeAchievementsReport>;
};

export const OverallStats: React.FC<Props> = ({lifeTimeAchievements}) => {
  const {data, loading, error} = lifeTimeAchievements;

  if (error) return null;

  const statsData = [
    {statValue: data?.books || 0, statLabel: 'Books'},
    {statValue: data?.minutes || 0, statLabel: 'Minutes'},
    {statValue: data?.words || 0, statLabel: 'Words'},
  ];

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '12px',
        alignItems: 'center',
        padding: '16px 16px 32px 16px',
        marginBottom: '16px',
        maxWidth: '400px',
        width: '100%',
      }}>
      <LifetimeIcon />
      <Box sx={{width: '100%'}}>
        <CardTitle sx={{marginBottom: '16px', textAlign: 'center'}}>
          Lifetime Achievement
        </CardTitle>
        {loading ? (
          <Loader />
        ) : (
          <Stats
            stats={statsData.map(stat => ({
              ...stat,
              statValue: formatNumber(stat.statValue),
            }))}
          />
        )}
      </Box>
    </Box>
  );
};
