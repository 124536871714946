import React from 'react';
import {Box} from '@mui/material';
import {Lesson} from '../Lesson/Lesson';
import {ReadingLevel} from '../ReadingLevel/ReadingLevel';
import {ReadingRecord} from '../ReadingRecord/ReadingRecord';
import {ReadingStats} from '../ReadingStats/ReadingStats';
import {TrickyWords} from '../TrickyWords/TrickyWords';
import {
  CurriculumProgress,
  QueryResult,
  ReadingRecordsList,
  WeeklyAchievement,
} from '../types';

type Props = {
  profileName: string;
  profileId: string;
  weeklyAchievements: QueryResult<WeeklyAchievement>;
  readingRecords: QueryResult<ReadingRecordsList[]>;
  curriculumProgress: QueryResult<CurriculumProgress>;
};

export const MobileDashboard: React.FC<Props> = ({
  profileName,
  profileId,
  curriculumProgress,
  readingRecords,
  weeklyAchievements,
}) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '24px',
      width: '100%',
    }}>
    <ReadingLevel
      profileName={profileName}
      curriculumProgress={curriculumProgress}
    />
    <ReadingStats
      profileName={profileName}
      weeklyAchievements={weeklyAchievements}
    />
    <ReadingRecord
      profileName={profileName}
      profileId={profileId}
      readingRecords={readingRecords}
    />
    <TrickyWords
      profileName={profileName}
      weeklyAchievements={weeklyAchievements}
    />
    <Lesson profileName={profileName} curriculumProgress={curriculumProgress} />
  </Box>
);
