import {gql} from '@apollo/client';

export const lifeTimeAchievementsReportQuery = gql`
  query LifeTimeAchievementsReport($profileId: ID!) {
    lifeTimeAchievementsReport(profileId: $profileId) {
      books
      minutes
      words
    }
  }
`;

export const weeklyAchievementsReportQuery = gql`
  query WeeklyAchievementsReport($profileId: ID!) {
    weeklyAchievementsReport(profileId: $profileId) {
      books
      gradeRole
      minutes
      trickyWords
      words
    }
  }
`;

export const readingRecordQuery = gql`
  query ReadingRecord($profileId: ID!) {
    readingRecord(profileId: $profileId) {
      bookTitle
      event
      minutes
      timestamp
      coverPhoto
    }
  }
`;

export const curriculumProgressReportQuery = gql`
  query CurriculumProgressReport($profileId: ID!) {
    curriculumProgressReport(profileId: $profileId) {
      currentLessonTitle
      gradeAndMonth
      gradeName
      gradeRole
      lessonDescription
      monthEquivalent
      nextSubGrade
      progress
      remainingQuests
      subGrade
    }
  }
`;

export const profileQuestsByIDQuery = gql`
  query ProfileQuestsByID($profileId: ID!, $filter: ProfileQuestFilter) {
    profileQuestsByID(profileId: $profileId, filter: $filter) {
      quests {
        id
      }
    }
  }
`;
