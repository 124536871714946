import React from 'react';
import {Box, Typography} from '@mui/material';
import {blue500} from 'shared/styles/palette';
import {Loader} from '../components/Loader';
import {ApolloError} from '@apollo/client';
import {PrimaryTypography} from './Typography';
import {ProgressBar} from './ProgressBar';

type Props = {
  progress: number;
  loading: boolean;
  error: ApolloError | undefined;
};

export const ProgressStatsContent: React.FC<Props> = ({
  progress,
  loading,
  error,
}) => {
  if (loading) return <Loader />;
  if (error) return <PrimaryTypography>-</PrimaryTypography>;

  return (
    <>
      <Typography
        variant="h6"
        sx={{
          fontSize: '26px',
          fontWeight: '800',
          color: blue500,
          lineHeight: '1.4',
          letterSpacing: '-0.104px',
        }}>
        {progress}%
      </Typography>
      <Box sx={{px: '16px', width: '100%'}}>
        <ProgressBar progress={progress} />
      </Box>
    </>
  );
};
