import React from 'react';
import {Box, Typography, Divider} from '@mui/material';
import {ReactComponent as ChevronDown} from 'shared/assets/icons/chevronDown.svg';
import {blue500, gray300} from 'shared/styles/palette';
import {Profile} from 'shared/types/profile';
import {getProfileCharacter} from '../../ProgressReportScreen.service';
import {ProfileCharacter} from '../../types';

type Props = {
  selectedProfile: Profile | null;
  isOpen: boolean;
  hasMultipleProfiles: boolean;
  toggleDropdown: () => void;
};

export const ProfileDropdownHeader: React.FC<Props> = ({
  selectedProfile,
  isOpen,
  hasMultipleProfiles,
  toggleDropdown,
}) => {
  const ProfileCharacterImage = getProfileCharacter(
    selectedProfile?.profileCharacter as ProfileCharacter,
  );

  return (
    <Box sx={{px: '24px'}}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '16px',
          cursor: 'pointer',
          userSelect: 'none',
          paddingBottom: '16px',
        }}
        onClick={toggleDropdown}>
        <ProfileCharacterImage />
        <Typography
          sx={{
            fontSize: '22px',
            fontWeight: '700',
            textTransform: 'capitalize',
            lineHeight: '1.4',
            letterSpacing: '-0.176px',
            color: blue500,
          }}>
          {selectedProfile?.name}
        </Typography>
        {hasMultipleProfiles && (
          <Box
            component="span"
            sx={{
              transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
              transition: 'transform 0.3s ease',
            }}>
            <ChevronDown />
          </Box>
        )}
      </Box>
      <Divider
        sx={{
          backgroundColor: gray300,
          height: '1px',
        }}
      />
    </Box>
  );
};
