import {Box, Typography} from '@mui/material';
import React from 'react';
import {blue500, gray900} from 'shared/styles/palette';

type Props = {
  statValue: string | number;
  statLabel: string;
};

export const StatsItem: React.FC<Props> = ({statValue, statLabel}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        flex: 1,
      }}>
      <Typography
        variant="h6"
        sx={{
          fontSize: '44px',
          fontWeight: '800',
          lineHeight: '1.3',
          letterSpacing: '-0.352px',
          color: blue500,
        }}>
        {statValue}
      </Typography>
      <Typography
        variant="body2"
        sx={{
          fontSize: '16px',
          lineHeight: '1.6',
          letterSpacing: '-0.048px',
          color: gray900,
        }}>
        {statLabel}
      </Typography>
    </Box>
  );
};
