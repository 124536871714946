import React from 'react';
import {Box} from '@mui/material';
import {Stats} from '../components/Stats';
import {CardBodyTypography} from '../components/ProgressCard/CardBodyTypography';
import {Loader} from '../components/Loader';
import {QueryResult, WeeklyAchievement} from '../types';
import {
  formatNumber,
  getReadingStatsCopy,
} from '../ProgressReportScreen.service';

type Props = {
  profileName: string;
  weeklyAchievements: QueryResult<WeeklyAchievement>;
};

export const ReadingStatsContent: React.FC<Props> = ({
  profileName,
  weeklyAchievements,
}) => {
  const {data, loading, error} = weeklyAchievements;

  if (loading) {
    return <Loader />;
  }

  const statsData = [
    {
      statValue: error ? '-' : formatNumber(data?.books || 0),
      statLabel: 'Books',
    },
    {
      statValue: error ? '-' : formatNumber(data?.minutes || 0),
      statLabel: 'Minutes',
    },
    {
      statValue: error ? '-' : formatNumber(data?.words || 0),
      statLabel: 'Words',
    },
  ];

  const content = getReadingStatsCopy(
    profileName,
    data?.gradeRole || 'learner',
    data?.books || 0,
  );

  return (
    <>
      <Box sx={{mb: '20px'}}>
        <Stats stats={statsData} />
      </Box>
      {!error && <CardBodyTypography>{content}</CardBodyTypography>}
    </>
  );
};
