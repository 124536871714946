import React from 'react';
import {Banner} from './Banner';
import {ReactComponent as Graph} from 'shared/assets/graph_green.svg';

type Props = {
  profileName: string;
};

export const DataSyncBanner: React.FC<Props> = ({profileName}) => {
  return (
    <Banner
      image={<Graph />}
      body={`Woohoo! ${profileName} has completed their first quest! We’re assessing their reading performance. Check back tomorrow for their progress report.`}
    />
  );
};
