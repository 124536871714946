import React from 'react';
import {Box, List, Divider} from '@mui/material';
import {gray300} from 'shared/styles/palette';
import {Profile} from 'shared/types/profile';
import {ProfileListItem} from './Profile.list-item';

type Props = {
  profiles: Profile[];
  selectedProfile: Profile | null;
  handleSelect: (profile: Profile) => void;
};

export const ProfileDropdownList: React.FC<Props> = ({
  profiles,
  selectedProfile,
  handleSelect,
}) => {
  return (
    <List sx={{padding: 0}}>
      {profiles
        .filter(profile => profile.id !== selectedProfile?.id)
        .map((profile, index, filteredProfiles) => (
          <Box key={profile.id}>
            <ProfileListItem profile={profile} handleSelect={handleSelect} />
            {index < filteredProfiles.length - 1 && (
              <Divider
                sx={{
                  backgroundColor: gray300,
                  height: '1px',
                }}
              />
            )}
          </Box>
        ))}
    </List>
  );
};
