import React from 'react';
import {Loader} from '../components/Loader';
import {LessonTitle} from './LessonTitle';
import {CardBodyTypography} from '../components/ProgressCard/CardBodyTypography';
import {QueryResult, CurriculumProgress} from '../types';
import {interpolateLessonDescription} from '../ProgressReportScreen.service';

type Props = {
  curriculumProgress: QueryResult<CurriculumProgress>;
  profileName: string;
};

export const LessonContent: React.FC<Props> = ({
  curriculumProgress,
  profileName,
}) => {
  const {data, error, loading} = curriculumProgress;

  if (loading) return <Loader />;
  if (error) return null;

  return (
    <>
      <LessonTitle>{data?.currentLessonTitle || ''}</LessonTitle>
      <CardBodyTypography>
        {interpolateLessonDescription(profileName, data?.lessonDescription)}
      </CardBodyTypography>
    </>
  );
};
