import {Box} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import {
  ExpressCheckoutElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import React, {forwardRef} from 'react';
import {trackEventWithIp} from 'shared/utils/analytics';
import featureFlags, {FEATURE_IDS} from 'shared/utils/featureFlags';
import styles from './ApplePayField.styles';
const expressCheckoutOptions = {
  layout: 'auto',
  buttonType: {
    applePay: 'plain',
  },
  buttonTheme: {
    applePay: 'black',
  },
};

const ApplePayField = forwardRef(props => {
  const stripe = useStripe();
  const elements = useElements();

  const onConfirm = async event => {
    if (!stripe || !elements) {
      return;
    }
    trackEventWithIp('Onboarding ProcessPaymentApplePay');

    await stripe
      .createPaymentMethod({
        elements,
      })
      .then(payload => props.onPayment(payload))
      .catch(error => {
        console.log('applePay createPaymentMethod error', error.message);
      });
  };

  const onReady = ({availablePaymentMethods}) => {
    if (availablePaymentMethods) {
      props.applePayReady(true);
    }
  };

  const showApplePayField = featureFlags.isOn(FEATURE_IDS.ENABLE_APPLE_PAY);

  return (
    <Box component="div">
      {showApplePayField && (
        <Box component="div" className={props.classes.expressCheckoutContainer}>
          <ExpressCheckoutElement
            options={expressCheckoutOptions}
            onReady={onReady}
            onConfirm={onConfirm}
          />
        </Box>
      )}
    </Box>
  );
});

export default withStyles(styles)(ApplePayField);
