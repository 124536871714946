import {trackEventWithIp} from '../shared/utils/analytics';

enum AnalyticsEvent {
  CancellationReasonSubmitted = 'Cancellation Reason Submitted',
  LevelAdjustmentStarted = 'LevelAdjustment Started',
  LevelAdjustmentCompleted = 'LevelAdjustment Completed',
  ShareLinkCopied = 'ShareLink Copied',
  CustomerPortalLinkClicked = 'CustomerPortalLink Clicked',
  OnboardingFlowLinkClicked = 'OnboardingFlowLink Clicked',
}

export enum ShareMethod {
  CopyToClipboard = 'CopyToClipboard',
  NativeShare = 'NativeShare',
}

type CancellationReasons = {
  cancelCategory: string;
  cancelDetail: string;
  feedback: string;
  isOnTrial: boolean;
  subscriptionSource: string;
};

const context = 'Customer portal';
function trackLevelAdjustmentStarted(profileId: string): void {
  analyticsTrack(AnalyticsEvent.LevelAdjustmentStarted, {
    profileId,
    context,
  });
}

function trackLevelAdjustmentCompleted(
  profileId: string,
  adjustmentRequest: string,
): void {
  analyticsTrack(AnalyticsEvent.LevelAdjustmentCompleted, {
    profileId,
    adjustmentRequest,
    context,
  });
}

function trackCopiedShareLink(shareMethod: ShareMethod): void {
  analyticsTrack(AnalyticsEvent.ShareLinkCopied, {
    shareMethod,
  });
}

function trackCustomerPortalLinkClicked(shareMethod: ShareMethod): void {
  analyticsTrack(AnalyticsEvent.CustomerPortalLinkClicked, {
    shareMethod,
  });
}

function trackOnboardingFlowLinkClicked(shareMethod: ShareMethod): void {
  analyticsTrack(AnalyticsEvent.OnboardingFlowLinkClicked, {
    shareMethod,
  });
}

function trackCancellationReasonSubmitted(input: CancellationReasons): void {
  analyticsTrack(AnalyticsEvent.CancellationReasonSubmitted, input);
}

function analyticsTrack(event: string, properties: Record<string, unknown>) {
  trackEventWithIp(event, properties);
}

const AnalyticsService = {
  trackCancellationReasonSubmitted,
  trackCopiedShareLink,
  trackCustomerPortalLinkClicked,
  trackLevelAdjustmentCompleted,
  trackLevelAdjustmentStarted,
  trackOnboardingFlowLinkClicked,
};

export default AnalyticsService;
