import {Box, CircularProgress} from '@mui/material';
import React from 'react';
import {gray300} from 'shared/styles/palette';

export const Loader = () => {
  return (
    <Box sx={{display: 'flex', justifyContent: 'center'}}>
      <CircularProgress sx={{color: gray300, width: '48px', height: '48px'}} />
    </Box>
  );
};
