import React from 'react';
import {ProgressCard} from '../components/ProgressCard/ProgressCard';
import {ReactComponent as TrickyWordsIcon} from 'shared/assets/icons/TrickyWordsIcon.svg';
import {QueryResult, WeeklyAchievement} from '../types';
import {TrickyWordsContent} from './TrickyWords.content';

type Props = {
  profileName: string;
  weeklyAchievements: QueryResult<WeeklyAchievement>;
};

export const TrickyWords: React.FC<Props> = ({
  profileName,
  weeklyAchievements,
}) => {
  return (
    <ProgressCard
      icon={<TrickyWordsIcon />}
      title={`Words ${profileName} Found Tricky`}>
      <TrickyWordsContent
        profileName={profileName}
        weeklyAchievements={weeklyAchievements}
      />
    </ProgressCard>
  );
};
