import React from 'react';
import Box from '@mui/material/Box';
import Header from './ReferralLink.header';
import QRCodeContainer from './ReferralLink.qr-code-container';
import LinkContainer from './ReferralLink.link-container';
import {ReferralLinkService} from './ReferralLink.service';

type Props = {
  header?: string;
  qrCodeHeader?: string;
  qrCodeBody?: string;
  linkHeader?: string;
  linkBody?: string;
  baseURL?: string;
  imageURL?: string;
  shareMessage?: string;
  imageMaxWidth?: string;
};

const ReferralLink = ({
  header,
  qrCodeHeader,
  qrCodeBody,
  linkHeader,
  linkBody,
  baseURL,
  imageURL,
  shareMessage,
  imageMaxWidth,
}: Props) => {
  const referralLink = ReferralLinkService.referralLink(baseURL);

  return (
    <Box>
      <Header text={header} />
      <QRCodeContainer header={qrCodeHeader} body={qrCodeBody} />
      {referralLink && (
        <LinkContainer
          header={linkHeader}
          body={linkBody}
          referralLink={referralLink}
          imageURL={imageURL}
          shareMessage={shareMessage}
          imageMaxWidth={imageMaxWidth}
        />
      )}
    </Box>
  );
};

export default ReferralLink;
