/*
 * A payment panel with stripe credit card components.
 * PaymentRequestButtonElement only renders on HTTPS!
 *
 * - Expects all the finalized data in the URL query params.
 * - If the `priceId` URL param is present expects 'priceId' to be an index into
 *   productDetails props, i.e.
 *   productDetails: {
 *     'price_1H4Z2pJZ2Z2Z2Z2Z2Z2Z2Z2Z2': {
 *       title: 'First product title',
 *       ...
 *     },
 *    'price_1H4Z2pANOTHERPRIZE': {
 *       title: 'Second product title',
 *       ...
 *     },
 *   }
 * - If the `product` URL param is not present, expects 'productDetails' to be an object, i.e.
 *   productDetails: { title: 'Product title', ... }
 */

import {withApollo} from '@apollo/client/react/hoc';
import {HighlightOff} from '@mui/icons-material';
import {Icon, IconButton, Paper} from '@mui/material';
import Alert from '@mui/material/Alert';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Collapse from '@mui/material/Collapse';
import {red} from '@mui/material/colors';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import Snackbar from '@mui/material/Snackbar';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import withStyles from '@mui/styles/withStyles';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import clsx from 'clsx';
import React from 'react';
import {ErrorBoundary} from 'react-error-boundary';
import padlock from 'shared/assets/icons/padlock.svg';
import quotation from 'shared/assets/icons/quotation.svg';
import stars from 'shared/assets/icons/stars.svg';
import poweredByStripe from 'shared/assets/poweredByStripe.svg';
import ApplePayField from 'shared/components/common/ApplePayField';
import CreditCardField from 'shared/components/common/CreditCardField';
import MoneyBackBadge from 'shared/components/common/MoneyBackBadge/MoneyBackBadge';
import {LAPTOP_BREAKPOINT, TABLET_BREAKPOINT} from 'shared/styles/breakpoints';
import {turquoise900} from 'shared/styles/palette';
import {trackEventWithIp} from 'shared/utils/analytics';
import {collectQueryParams} from 'shared/utils/queryParams';
import {QueryParams, StringParam} from 'use-query-params';
import {withDebounce} from '../../utils/debounce';
import {CustomerProductType, supportsDigital} from '../../utils/productType';
import {sendSlackMessage, SlackMessageTypes} from '../../utils/slack';
import ElloAppSubscription from './ElloAppSubscription';
import {
  ESTIMATE_TAX,
  GET_REFERRAL_RECEIVER_REWARD,
  VALIDATE_COUPON,
} from './PaymentPanel.query';
import {
  getMutation,
  getShippingAmount,
  PaymentAction,
} from './PaymentPanel.service';
import styles from './PaymentPanel.styles';
import Total from './PaymentPanel.total';
import ShippingOptionSelector from './ShippingOptions/ShippingOptionSelector';
import TrialCompliance from './TrialCompliance';

const STRIPE_KEY =
  process.env.NODE_ENV === 'production' &&
  !(
    window.location.href.includes('staging') ||
    window.location.href.includes('localhost') ||
    window.location.href.includes('webflow.io') ||
    window.location.href.includes('rav')
  )
    ? 'pk_live_RfrSw6WcCh2q9OfNA5l7uFZt00Qe3RDL6Z'
    : 'pk_test_woKjziuu3AMwlJg2EERTa00X00p97XUOTC';

const stripePromise = loadStripe(STRIPE_KEY);

const queryParams = {coupon: StringParam};

class PaymentPanel extends React.Component {
  constructor(props) {
    super(props);

    // Get product from query params and index into product details if priceId specified
    const queryParams = collectQueryParams();

    const priceId = queryParams.priceId;
    const productDetails = !!priceId
      ? props.productDetails[priceId]
      : props.productDetails;

    const isValidShipping =
      productDetails &&
      productDetails.shipping !== undefined &&
      parseInt(productDetails.shipping) !== 0;

    const displayShippingOptions =
      productDetails.shippingOptions?.length && !isValidShipping;

    this.state = {
      stripe: null,
      error: null,
      errorVisible: false,
      loading: false,
      validatingCoupon: false, // used to keep track of when we are validating the coupon
      couponVisible: false,
      couponError: '', // Error for the coupon field
      couponTitle: '', // Successful coupon title, if we were able to get it!
      showShipping: isValidShipping,
      showShippingOptions: displayShippingOptions,
      disableCoupon:
        !!props.disableCoupon || props.action === PaymentAction.PURCHASE_COUPON, // Do not support coupon codes for coupon purchase
      total: 0,
      discount: 0,
      tax: 0,
      isTablet: window.matchMedia(`(min-width: ${TABLET_BREAKPOINT}px)`)
        .matches,
      isDesktop: window.matchMedia(`(min-width: ${LAPTOP_BREAKPOINT}px)`)
        .matches,
      paymentInputComplete: false, // used to track if all payment information has been entered
      coupon: '',
      isApplePayReady: false,
      referralReward: {
        couponId: '',
        loading: false,
        error: null,
      },
      giftCode: '',
    };

    if (!!productDetails) {
      this.priceString = productDetails.price;
      this.titleString = productDetails.title;
      this.features = productDetails.features;
      this.action = props.action;
      this.productType =
        productDetails.productType || CustomerProductType.physicalSubscription;
      this.trialPeriodDays = productDetails.trialPeriodDays || 0;
      this.chargeableTrialAmount = queryParams.chargeableTrialAmount;
      this.shippingOptions = productDetails.shippingOptions;
      this.shipping = getShippingAmount(productDetails);
      this.taxCode = productDetails.taxCode;
    } else {
      if (priceId) {
        console.log(
          'Error: priceId was specified but no product details for this priceId found.',
        );
      }
      this.state.error =
        'An error occurred. Please contact us at (415) 214-8119.';
      this.state.errorVisible = true;
    }

    this.taxCalculationId = null;
    this.supportsApplePay = props.supportsApplePay;
    this.creditCardField = React.createRef();
    this.couponField = React.createRef();
  }

  tabletMediaHandler = e => this.setState({isTablet: e.matches});
  desktopMediaHandler = e => this.setState({isDesktop: e.matches});

  componentDidMount() {
    this.handleGiftPromoCode();

    this.fetchReferralReward();
    // update total
    this.calculateTotal();
    // update width and height
    window
      .matchMedia(`(min-width: ${TABLET_BREAKPOINT}px)`)
      .addEventListener('change', this.tabletMediaHandler);

    window
      .matchMedia(`(min-width: ${LAPTOP_BREAKPOINT}px)`)
      .addEventListener('change', this.desktopMediaHandler);

    // If a Rewardful coupon is specified, but no coupon is on the query param, we inherit it;
    // that is, a user-specified or query-specified coupon overwrites the Rewardful-specified one.
    if (window.rewardful) {
      window.rewardful('ready', () => {
        if (window.Rewardful.coupon) {
          this.setState({coupon: window.Rewardful.coupon, couponVisible: true});
          this.handleCoupon(window.Rewardful.coupon);
        }
      });
    }

    // Trigger coupon validation and confirmation animation if the coupon field was given
    // a value from the query string
    if (this.couponField.current && this.couponField.current.value) {
      this.setState({couponVisible: true});
      this.handleCoupon(this.couponField.current.value, null);
    }

    // If a productDetail coupon is specified then use it.
    // TODO: Confirm that this is the right behavior.
    if (this.props.productDetails?.coupon && !this.state.disableCoupon) {
      this.setState({
        coupon: this.props.productDetails.coupon,
        couponVisible: true,
      });
      this.handleCoupon(this.props.productDetails.coupon);
    }
  }

  componentWillUnmount() {
    window
      .matchMedia(`(min-width: ${TABLET_BREAKPOINT}px)`)
      .removeEventListener('change', this.mediaHandler);
  }

  updateWindowDimensions = () => {
    this.setState({width: window.innerWidth, height: window.innerHeight});
  };

  applePayReady = isReady => {
    this.setState({isApplePayReady: isReady});
  };

  logError = error => {
    const message = `An error was encountered by the Stripe payment element: \n\nURL: ${window.location.href} \n\nError: ${error.message}`;
    sendSlackMessage(SlackMessageTypes.OnboardingError, message);
  };

  handleErrorClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({errorVisible: false});
  };

  /**
   * Triggers payment
   */
  performAction = withDebounce(() => {
    trackEventWithIp('Onboarding Pay', {
      productType: this.productType,
      estimatedAmountPaid: this.state.total * 100, // convert to cents
    });
    this.setState({loading: true});
    this.creditCardField.current.processPayment();
  }, 200);

  /**
   * Callback handler for stripe payment: triggers our onboarding API call.
   */
  handlePayment = result => {
    if (result.error) {
      // Track error of any kind (note that success gets tracked on the backend side)
      trackEventWithIp('Onboarding PaymentError', {
        error: result.error.message,
      });
      this.setState({loading: false});
      this.setState({error: result.error.message, errorVisible: true});
      return;
    }

    // Connect analytics events (bubblewrap for ad blocker)
    let anonymousId;
    try {
      anonymousId = window.analytics.user().anonymousId();
    } catch (e) {
      console.warn(e);
    }

    const {action, flowName, stripePriceId} = this.props;
    if (action && !Object.values(PaymentAction).includes(action)) {
      // Action specified but not defined
      this.setState({
        error: 'Oops – something went wrong!',
        errorVisible: true,
      });
      console.log(`Error: '${action}' is not a valid payment action`);

      return;
    }

    const {mutation, supportsTrials} = getMutation(action, this.productType);
    // Capture coupon if there is any
    const params = collectQueryParams();
    const inputVariables = {
      // 'product' URL query param overwrites stripe price ID
      // (Note, we're effectively allowing users to specify any price ID here on the frontend)
      stripePriceId: params.priceId || stripePriceId,
      flowName: flowName,
      phone: params.phone,
      paymentMethod: result.paymentMethod.id, // we just captured with stripe
      content: {
        ...params,
        tax: this.state.tax,
        shipping: this.shipping,
      }, // may want to remove the ones we're already sending so we don't double
      name: params.name,
      email: params.email,
      coupon:
        this.couponField?.current?.value ||
        params.coupon ||
        this.state.referralReward.couponId ||
        this.state.giftCode ||
        undefined,
      uid: anonymousId,
      referral: !!window.Rewardful ? window.Rewardful.referral : undefined,
      productType: this.productType,
      stripeBillingPostalCode:
        result.paymentMethod.billing_details.address.postal_code,
      giftCode: this.state.giftCode,
    };

    if (supportsTrials) {
      inputVariables.trialPeriodDays = this.trialPeriodDays;
    }

    if (params.transaction_id) {
      inputVariables.tuneTransactionId = params.transaction_id;
    }

    if (this.state.disableCoupon) {
      inputVariables.coupon = '';
    }

    if (params.isbns) {
      inputVariables.isbns = params.isbns;
    }

    if (params.referredBy) {
      inputVariables.referralCode = params.referredBy;
    }

    if (params.giftBundle) {
      inputVariables.giftBundle = params.giftBundle;
      inputVariables.amount = Math.trunc(this.state.total * 100);
      inputVariables.taxCalculationId = this.taxCalculationId;
    }

    if (params.userUid) {
      inputVariables.userUid = params.userUid;
    }

    this.props.client
      .mutate({mutation, variables: inputVariables})
      .catch(error => {
        this.setState({loading: false});

        this.setState({
          // A bit hacky, but for now we want to be pretty generous with showing all errors that occur during onboarding
          // so that our beta customers can help us debug!
          error: error.message.replaceAll('GraphQL error: ', ''),
          errorVisible: true,
        });
        console.log('Error calling API:', error);
      })
      .then(onboardingResult => {
        if (onboardingResult) {
          const {
            onboarding,
            onboardDigitalCustomer,
            purchaseCoupon,
            purchaseGift,
            resubscribe,
          } = onboardingResult.data;

          this.props.onConfirm(
            onboarding ||
              onboardDigitalCustomer ||
              purchaseCoupon ||
              purchaseGift ||
              resubscribe,
            {
              email: params.email,
              phone: params.phone,
              productType: this.productType,
            },
          );
        }
        this.setState({loading: false});
      });
  };

  /**
   * Coupon field onChange handler.
   *
   * value: value of the field
   * setQuery: query update handler, omitted on first page load call
   */
  handleCoupon = (value, setQuery) => {
    if (this.state.disableCoupon) {
      return;
    }
    this.setState({couponError: '', validatingCoupon: true});

    const params = collectQueryParams();
    const {stripePriceId} = this.props;
    const priceId = params.priceId || stripePriceId;

    if (value) {
      this.props.client
        .query({
          query: VALIDATE_COUPON,
          variables: {coupon: value, priceId},
        })
        .then(({data}) => {
          this.couponField.current.blur();
          if (setQuery) setQuery({coupon: value});
          this.setState({
            couponError: '',
            couponTitle: data.validateCoupon.name,
            validatingCoupon: false,
            giftCode: params.giftCode ? value : '',
          });
          this.calculateTotal(
            data.validateCoupon.amountOff,
            data.validateCoupon.percentOff,
          );
        })
        .catch(error => {
          if (error.toString().includes('Coupon Expired')) {
            this.setState({
              couponError: 'Coupon has expired',
              couponTitle: '',
              validatingCoupon: false,
            });
          } else {
            this.setState({
              couponError: 'Coupon not valid',
              couponTitle: '',
              validatingCoupon: false,
            });
          }
          if (params.giftCode) {
            this.setState({coupon: value, couponVisible: true});
          }
          this.calculateTotal();
          if (setQuery) setQuery({coupon: ''});
        });
    } else {
      if (setQuery) setQuery({coupon: ''});
      this.setState({
        couponError: '',
        couponTitle: '',
        validatingCoupon: false,
      });
      this.calculateTotal();
    }
  };

  estimateTax = total => {
    const params = collectQueryParams();

    // Sometimes we don't  collect shipping address
    // in those cases don't estimate tax
    const hasNoShippingAddress =
      !params.address &&
      !params.address_city &&
      !params.address_state &&
      !params.address_zip;

    if (hasNoShippingAddress) {
      return;
    }

    const {stripePriceId} = this.props;
    const priceId = params.priceId || stripePriceId;

    const totalAmount = isNaN(total) ? 0 : total * 100;
    const amount = parseInt(Number(totalAmount).toFixed(0));

    this.props.client
      .query({
        query: ESTIMATE_TAX,
        variables: {
          amount,
          line1: params.address,
          city: params.address_city,
          state: params.address_state,
          postalCode: params.address_zip,
          priceId: priceId,
          taxCode: this.taxCode,
        },
      })
      .then(({data}) => {
        // amountTotal is inclusive of tax
        const totalAmount = data.estimateTax.amountTotal / 100;
        const taxEstimate = data.estimateTax.taxAmountExclusive
          ? data.estimateTax.taxAmountExclusive / 100
          : data.estimateTax.taxAmountInclusive / 100;
        this.setState({
          total: parseFloat(Number(totalAmount).toFixed(2)),
          tax: parseFloat(Number(taxEstimate).toFixed(2)),
        });
        this.taxCalculationId = data.estimateTax.id;
      })
      .catch(error => {
        if (error.message.includes('No such price')) {
          const message = `An error was encountered by a user while loading price during onboarding: \n\npriceId: ${priceId} \nURL: ${window.location.href}`;
          sendSlackMessage(SlackMessageTypes.OnboardingError, message);
        }
        this.setState({
          error: error.message,
          errorVisible: true,
        });
      });
  };

  calculateTotal = (amountOff, percentOff) => {
    if (this.chargeableTrialAmount) {
      this.setState({total: this.chargeableTrialAmount});
      return;
    }
    if (!amountOff && !percentOff) {
      const total = (Number(this.priceString) + Number(this.shipping)).toFixed(
        2,
      );
      this.setState({total});
      this.estimateTax(total);
    } else if (amountOff) {
      const price = Number(this.priceString) - amountOff / 100;
      const total = (price + Number(this.shipping)).toFixed(2);
      this.setState({total, discount: (amountOff / 100).toFixed(2)});
      this.estimateTax(total);
    } else if (percentOff) {
      const total = (Number(this.priceString) + Number(this.shipping)).toFixed(
        2,
      );
      const discountedTotal = (total * ((100 - percentOff) / 100)).toFixed(2);
      this.setState({
        total: discountedTotal,
        discount: (total * (percentOff / 100)).toFixed(2),
      });
      this.estimateTax(discountedTotal);
    }
  };

  fetchReferralReward = () => {
    const params = collectQueryParams();

    const isDigitalSubscription =
      this.productType === CustomerProductType.digitalSubscription;
    const isRefferedCustomer = !!params.referredBy;
    if (!isRefferedCustomer) {
      return;
    }

    if (!isDigitalSubscription) {
      this.setState(prevState => ({
        referralReward: {
          ...prevState.referralReward,
          error:
            'Referral rewards are only available for digital subscriptions',
          showErrorMessage: true,
        },
      }));
      return;
    }

    this.setState(prevState => ({
      referralReward: {
        ...prevState.referralReward,
        loading: true,
        error: null,
        showErrorMessage: false,
      },
    }));
    this.props.client
      .query({
        query: GET_REFERRAL_RECEIVER_REWARD,
        variables: {
          referralCode: params.referredBy,
        },
      })
      .then(async ({data}) => {
        const {couponId} = data?.referralReceiverReward || {};
        if (couponId) {
          this.handleCoupon(couponId);
        }
        this.setState(prevState => ({
          referralReward: {
            ...prevState.referralReward,
            couponId,
            loading: false,
          },
        }));
      })
      .catch(error => {
        this.setState(prevState => ({
          referralReward: {
            ...prevState.referralReward,
            loading: false,
            error: error.message,
            showErrorMessage: false,
          },
        }));
      });
  };

  handleShippingMethodChange = event => {
    const selectedShippingOption = this.shippingOptions.find(
      option => option.type === event.target.value,
    );
    this.shipping = selectedShippingOption.amount;
    this.calculateTotal();
  };

  complete = isComplete => this.setState({paymentInputComplete: isComplete});

  handleGiftPromoCode = () => {
    const params = collectQueryParams();
    const giftCode = params.giftCode;

    if (giftCode) {
      this.handleCoupon(giftCode);
    }
  };

  render() {
    const {classes} = this.props;

    const trialTotal = this.chargeableTrialAmount
      ? this.chargeableTrialAmount
      : '0';

    const amountToCharge = this.trialPeriodDays ? trialTotal : this.state.total;
    const parsedAmount = parseFloat(amountToCharge);
    const validAmountToCharge = isNaN(parsedAmount) ? 0 : parsedAmount;
    const stripeAmount = Math.round(validAmountToCharge * 100);

    const stripeElementOptions = {
      mode: 'subscription',
      amount: stripeAmount,
      currency: 'usd',
      paymentMethodCreation: 'manual',
    };

    const referralReward = this.state.referralReward;

    const shouldDisplayTax = this.state.tax !== 0 && this.state.total > 0;

    const getPromoMessage = () => {
      const hasRewardApplied =
        referralReward.couponId && this.state.couponTitle;
      const hasGiftApplied = this.state.giftCode && this.state.couponTitle;

      if (hasRewardApplied) {
        return 'Promo applied: $0 Today and $14.99 off next month';
      }
      if (hasGiftApplied) {
        return `Gift card applied: ${this.state.giftCode}`;
      }
      return 'Apply Promo';
    };

    const shouldDisplayTrialCompliance =
      supportsDigital(this.productType) &&
      !!this.trialPeriodDays &&
      !this.state.validatingCoupon &&
      !referralReward.loading;

    const creditCardField = (
      <CreditCardField
        ref={this.creditCardField}
        complete={this.complete}
        onPayment={this.handlePayment}
        isApplePayReady={this.state.isApplePayReady}
      />
    );

    const stripeElements = (
      <Elements stripe={stripePromise} options={stripeElementOptions}>
        <ApplePayField
          onPayment={this.handlePayment}
          applePayReady={this.applePayReady}
        />
        {creditCardField}
      </Elements>
    );

    const cardOnlyStripeElements = (
      <Elements stripe={stripePromise}>{creditCardField}</Elements>
    );

    const stripeProviderSection = (
      <div className={classes.stripeSectionContainer}>
        {this.supportsApplePay && (
          <ErrorBoundary
            fallback={cardOnlyStripeElements}
            onError={this.logError}>
            {stripeElements}
          </ErrorBoundary>
        )}
        {!this.supportsApplePay && cardOnlyStripeElements}
      </div>
    );

    const testimonial = (
      <Paper elevation={0} className={classes.testimonialSection}>
        <Box display="flex" flexDirection="row">
          <Box>
            <img
              src={quotation}
              alt="Quotation mark"
              className={classes.quotationMark}
            />
          </Box>
          <Box>
            <Typography className={[classes.testimonial]}>
              My son just finished all his books, asked if he can read them
              again and shouted, "I love reading!" I am speechless. This is just
              amazing to watch...and listen to!
            </Typography>
            <Box className={classes.rating}>
              <img
                src={stars}
                className={classes.stars}
                alt="Five star rating"
                align="left"
              />
              <Typography align="left">Sara D.</Typography>
            </Box>
          </Box>
        </Box>
      </Paper>
    );

    const stripeBadge = (
      <Box className={classes.stripeBadgeContainer}>
        <Box className={classes.stripeBadgeInnerContainer}>
          <img src={padlock} alt="Padlock icon" />
          <Typography className={classes.safeAndSecureText} align="left">
            Guaranteed safe & secure {this.state.isTablet && 'checkout'}
          </Typography>
        </Box>
        <img
          src={poweredByStripe}
          alt="Powered by Stripe"
          className={classes.stripeBadge}
        />
      </Box>
    );

    const contactUs = (
      <Typography className={classes.contactUs}>
        Text us right now with questions at{' '}
        <span>
          <a href={'sms://+14152148119?body=Hi'}>(415) 214-8119</a>
        </span>{' '}
        or email <a href="mailto: support@ello.com">support@ello.com</a>
      </Typography>
    );

    const shipping = (
      <Box display="flex" justifyContent="space-between">
        <Box>
          <Typography className={classes.pricingLabel} align="left">
            Shipping
          </Typography>
        </Box>
        <Grid>
          <Typography className={classes.pricing} align="right">
            ${this.shipping}
          </Typography>
        </Grid>
      </Box>
    );

    const tax = (
      <Box display="flex" justifyContent="space-between">
        <Box>
          <Typography className={classes.pricingLabel} align="left">
            Estimated Tax
          </Typography>
        </Box>
        <Grid>
          <Typography className={classes.pricing} align="right">
            ${this.state.tax.toFixed(2)}
          </Typography>
        </Grid>
      </Box>
    );

    const coupon = (
      <>
        {/* Note that we use this instead of the withQueryParams wrapper below, because that
            doesn't pass through the ref correctly. */}
        <QueryParams config={queryParams}>
          {({query, setQuery}) => {
            return (
              <Collapse in={this.state.couponVisible}>
                <Box component="div" className={classes.couponContainer}>
                  <Collapse in={!this.state.couponTitle}>
                    <TextField
                      variant="outlined"
                      className={classes.couponField}
                      label={
                        this.state.couponError
                          ? this.state.couponError
                          : 'Redeem Code'
                      }
                      value={this.state.coupon || query.coupon || ''}
                      fullWidth
                      error={!!this.state.couponError}
                      inputRef={this.couponField}
                      onChange={({target: {value}}) => {
                        setQuery({coupon: value});
                        this.setState({coupon: value});
                        this.handleCoupon(value, setQuery);
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">
                            {this.state.validatingCoupon ? (
                              <CircularProgress
                                size="small"
                                className={classes.couponProgress}
                              />
                            ) : (
                              <>
                                {this.state.couponError ? (
                                  <IconButton
                                    className={
                                      classes.couponTextFieldIconButton
                                    }
                                    onClick={() => {
                                      this.handleCoupon('', setQuery);
                                      this.couponField.current.value = '';
                                      this.setState({coupon: ''});
                                    }}
                                    size="large">
                                    <HighlightOff />
                                  </IconButton>
                                ) : (
                                  <Button
                                    onClick={() =>
                                      this.handleCoupon(
                                        this.couponField.current.value,
                                        setQuery,
                                      )
                                    }
                                    color="primary">
                                    Apply
                                  </Button>
                                )}
                              </>
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Collapse>

                  <Collapse in={!!this.state.couponTitle}>
                    <Box display="flex" justifyContent="space-between">
                      <Box display="flex" justifyContent="center">
                        {!referralReward.couponId && (
                          <IconButton
                            size="small"
                            onClick={() => {
                              this.handleCoupon('', setQuery);
                              this.couponField.current.value = '';
                            }}
                            aria-label="delete">
                            <HighlightOff />
                          </IconButton>
                        )}
                        <Typography
                          className={classes.promoApplied}
                          display="inline"
                          align="left">
                          Promo Applied
                        </Typography>
                      </Box>
                      <Box>
                        <Typography
                          className={classes.successCouponDiscount}
                          align="right">
                          -${this.state.discount}
                        </Typography>
                      </Box>
                    </Box>
                    <Typography
                      className={clsx(
                        classes.successCoupon,
                        referralReward.couponId && classes.leftPaddingOff,
                      )}
                      component="p"
                      variant="caption"
                      align="left"
                      gutterBottom>
                      {this.state.couponTitle}
                    </Typography>
                  </Collapse>
                </Box>
              </Collapse>
            );
          }}
        </QueryParams>

        <Collapse in={!this.state.couponVisible}>
          {this.state.referralReward.loading || this.state.validatingCoupon ? (
            <Box sx={{display: 'flex', alignItems: 'center', gap: '8px'}}>
              <CircularProgress thickness={5} size={16} />
              <Typography
                variant="body2"
                sx={{color: turquoise900, fontWeight: 700}}>
                Verifying...
              </Typography>
            </Box>
          ) : (
            <Typography
              align="left"
              className={classes.redeemCode}
              component="p"
              variant="caption"
              onClick={() => {
                this.setState({couponVisible: true});

                trackEventWithIp('Onboarding EnterCoupon');
              }}>
              {getPromoMessage()}
            </Typography>
          )}
        </Collapse>
      </>
    );

    const line = (
      <Grid container>
        <Grid xs={12}>
          <hr className={classes.lineBreak} />
        </Grid>
      </Grid>
    );

    const paymentButton = (
      <Button
        className={classes.paymentButton}
        variant="contained"
        color="primary"
        onClick={this.performAction}
        endIcon={<Icon>lock</Icon>}
        disabled={!this.state.paymentInputComplete || this.state.couponError}
        disableElevation>
        Confirm Payment
      </Button>
    );

    const termsAndPrivacyPolicy = (
      <Typography
        align="center"
        className={classes.terms}
        component="p"
        variant="caption">
        By clicking pay, you agree to our{' '}
        <a
          href="https://www.helloello.com/terms"
          target="_blank"
          rel="noopener noreferrer">
          Terms
        </a>{' '}
        and{' '}
        <a
          href="https://www.helloello.com/privacy"
          target="_blank"
          rel="noopener noreferrer">
          Privacy Policy
        </a>
        .
      </Typography>
    );

    const referralRewardError = (
      <Typography
        variant="body1"
        sx={{
          color: red[500],
          fontSize: '12.3px',
          textAlign: 'left',
        }}>
        {this.state.referralReward.showErrorMessage
          ? this.state.referralReward.error
          : "We couldn't apply your reward"}
      </Typography>
    );

    return (
      <>
        <Backdrop className={classes.backdrop} open={this.state.loading}>
          <CircularProgress color="primary" />
        </Backdrop>

        {this.state.isDesktop ? (
          <Grid container className={classes.container}>
            <Grid xs={12} md={6} className={classes.sectionLeft}>
              {stripeProviderSection}
              {testimonial}
              {stripeBadge}
              {contactUs}
            </Grid>

            <Grid xs={12} md={6} className={classes.sectionRight}>
              <Box className={classes.rightColumnInnerContainer}>
                <Box className={classes.subscriptionContainer}>
                  <ElloAppSubscription
                    titleString={this.titleString}
                    priceString={this.priceString}
                    features={this.features}
                    trialPeriodDays={this.trialPeriodDays}
                    chargeableTrialAmount={this.chargeableTrialAmount}
                    classes={classes}
                  />
                  {this.state.showShippingOptions && (
                    <ShippingOptionSelector
                      shippingOptions={this.shippingOptions}
                      onChange={this.handleShippingMethodChange}
                    />
                  )}
                  {this.state.showShipping && shipping}
                  {shouldDisplayTax && tax}
                  {shouldDisplayTrialCompliance && (
                    <TrialCompliance
                      classes={classes}
                      priceString={this.priceString}
                      trialPeriodDays={this.trialPeriodDays}
                      rewardCoupon={referralReward.couponId}
                      giftCode={this.state.giftCode}
                    />
                  )}
                  {!!referralReward.error && referralRewardError}
                  {this.state.disableCoupon ? <br /> : coupon}
                  {line}

                  <Total
                    classes={classes}
                    trialPeriodDays={this.trialPeriodDays}
                    total={this.state.total}
                    chargeableTrialAmount={this.chargeableTrialAmount}
                  />
                  {!this.props.noRefundGuarantee && (
                    <Box className={classes.moneyBackBox}>
                      <MoneyBackBadge />
                    </Box>
                  )}
                </Box>
                <Box>
                  {paymentButton}
                  {termsAndPrivacyPolicy}
                </Box>
              </Box>
            </Grid>
          </Grid>
        ) : (
          <Grid
            container
            className={this.state.isTablet ? classes.container : ''}>
            <Grid xs={12}>
              <ElloAppSubscription
                titleString={this.titleString}
                priceString={this.priceString}
                features={this.features}
                trialPeriodDays={this.trialPeriodDays}
                chargeableTrialAmount={this.chargeableTrialAmount}
                classes={classes}
              />
              {this.state.showShippingOptions && (
                <ShippingOptionSelector
                  shipping={this.shipping}
                  shippingOptions={this.shippingOptions}
                  onChange={this.handleShippingMethodChange}
                />
              )}
              {this.state.showShipping && shipping}
              {shouldDisplayTax && tax}
              {shouldDisplayTrialCompliance && (
                <TrialCompliance
                  classes={classes}
                  priceString={this.priceString}
                  trialPeriodDays={this.trialPeriodDays}
                  rewardCoupon={referralReward.couponId}
                  giftCode={this.state.giftCode}
                />
              )}
              {!!referralReward.error && referralRewardError}
              {this.state.disableCoupon ? <br /> : coupon}
              {line}

              <Total
                classes={classes}
                trialPeriodDays={this.trialPeriodDays}
                total={this.state.total}
                chargeableTrialAmount={this.chargeableTrialAmount}
              />
              {!this.props.noRefundGuarantee && (
                <Box className={classes.moneyBackBox}>
                  <MoneyBackBadge />
                </Box>
              )}
              {stripeProviderSection}
              {paymentButton}
              {termsAndPrivacyPolicy}
              {stripeBadge}
              {testimonial}
              {contactUs}
            </Grid>
          </Grid>
        )}

        <Snackbar
          open={this.state.errorVisible}
          onClose={this.handleErrorClose}>
          <Alert onClose={this.handleErrorClose} severity="error">
            {this.state.error}
          </Alert>
        </Snackbar>
      </>
    );
  }
}

export default withStyles(styles)(withApollo(PaymentPanel, {withRef: true}));
