import React from 'react';
import {Card, CardContent, Box} from '@mui/material';
import {CardTitle} from './CardTitle';
import {gray100} from 'shared/styles/palette';

type Props = {
  icon: React.ReactNode;
  title: string;
  isEmpty?: boolean;
};

export const ProgressCard: React.FC<Props> = ({
  icon,
  title,
  children,
  isEmpty,
}) => {
  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '24px 24px 32px 24px',
        border: `1px solid ${gray100}`,
        borderRadius: '16px',
        boxShadow: '4px 8px 20px 0px rgba(0, 0, 0, 0.05)',
        maxWidth: '380px',
        width: '100%',
        opacity: isEmpty ? 0.3 : 1,
      }}>
      <CardContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
          padding: 0,
          '&:last-child': {paddingBottom: 0},
        }}>
        <Box sx={{marginBottom: '12px'}}>{icon}</Box>
        <CardTitle sx={{marginBottom: 2, textAlign: 'center'}}>
          {title}
        </CardTitle>
        <Box sx={{width: '100%'}}>{children}</Box>
      </CardContent>
    </Card>
  );
};
