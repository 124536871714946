import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {trackPageWithIp} from '../shared/utils/analytics';

export function usePageViews() {
  const location = useLocation();

  useEffect(() => {
    trackPageWithIp(location.pathname);
  }, [location]);
}
