import React from 'react';
import {ReactComponent as ReadingIcon} from 'shared/assets/icons/ReadingIcon.svg';
import {ProgressCard} from '../components/ProgressCard/ProgressCard';
import {QueryResult, WeeklyAchievement} from '../types';
import {ReadingStatsContent} from './ReadingStats.content';

type Props = {
  profileName: string;
  weeklyAchievements: QueryResult<WeeklyAchievement>;
};

export const ReadingStats: React.FC<Props> = ({
  profileName,
  weeklyAchievements,
}) => {
  return (
    <ProgressCard icon={<ReadingIcon />} title="This Week's Reading">
      <ReadingStatsContent
        profileName={profileName}
        weeklyAchievements={weeklyAchievements}
      />
    </ProgressCard>
  );
};
