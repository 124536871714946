import React from 'react';
import {Box} from '@mui/material';
import {ReactComponent as ReadingLevelIcon} from 'shared/assets/icons/ReadingLevelIcon.svg';
import {ReadingLevelInfo} from './ReadingLevelInfo';
import {ProgressStats} from './ProgressStats';
import {ProgressCard} from '../components/ProgressCard/ProgressCard';
import {CardBodyTypography} from '../components/ProgressCard/CardBodyTypography';
import {CurriculumProgress, QueryResult} from '../types';
import {getReadingLevelContent} from '../ProgressReportScreen.service';

type Props = {
  profileName: string;
  curriculumProgress: QueryResult<CurriculumProgress>;
};

export const ReadingLevel: React.FC<Props> = ({
  profileName,
  curriculumProgress,
}) => {
  const {data, error, loading} = curriculumProgress;

  const readingLevel = data?.gradeAndMonth;
  const progress = data?.progress || 0;

  const content = data
    ? getReadingLevelContent(profileName, data)
    : `${profileName} started a new curriculum! To see their progress, encourage them to complete a quest.`;

  return (
    <ProgressCard
      icon={<ReadingLevelIcon />}
      title={`${profileName}'s Reading Level`}>
      <Box>
        <Box sx={{display: 'flex', gap: '20px', mb: '20px'}}>
          <ReadingLevelInfo
            readingLevel={readingLevel}
            loading={loading}
            error={error}
          />
          <ProgressStats progress={progress} loading={loading} error={error} />
        </Box>
        {!loading && !error && (
          <CardBodyTypography>{content}</CardBodyTypography>
        )}
      </Box>
    </ProgressCard>
  );
};
