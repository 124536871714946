import {GrowthBook} from '@growthbook/growthbook-react';
import {trackEventWithIp} from 'shared/utils/analytics';

export const FEATURES_ENDPOINT =
  process.env.NODE_ENV === 'production' &&
  !(
    window.location.href.includes('staging') ||
    window.location.href.includes('localhost') ||
    window.location.href.includes('rav')
  )
    ? 'https://cdn.growthbook.io/api/features/key_prod_5671e0757a37c408'
    : window.location.href.includes('staging') ||
      window.location.href.includes('rav') ||
      process.env.REACT_APP_VARIANT === 'staging' || // Local dev with staging servers
      window.location.href.includes('webflow.io') // Webflow staging
    ? 'https://cdn.growthbook.io/api/features/key_stag_055afd9d7c1b4896'
    : 'https://cdn.growthbook.io/api/features/key_debu_29b449cfe21510c0';

const featureFlags = new GrowthBook({
  trackingCallback: (experiment, result) => {
    // Pass GrowthBook experiment input back to segment (it then makes its way back to GrowthBook)
    // via BigQuery or Mixpanel
    if (window.analytics) {
      trackEventWithIp('Experiment Viewed', {
        experimentId: experiment.key,
        variationId: result.variationId,
      });
    }
  },
});

export default featureFlags;

export const FEATURE_IDS = {
  ADD_PROFILES: 'customer-portal-add-profiles',
  ADJUST_READING_LEVEL: 'adjust-reading-level',
  ALLOW_DELETE_ACCOUNT: 'allow-delete-account',
  ALLOW_REMOVE_PROFILE: 'customer-portal-allow-remove-profile',
  ALLOW_SELF_SERVICE_LEVEL_ADJUST: 'allow-self-service-level-adjust',
  BOOK_MGMT_SCREENS: 'customer-portal-book-mgmt-screens',
  CANCELLATION_MODAL: 'customer-portal-cancellation-modal',
  CUSTOMER_PORTAL_SUB: 'customer-portal-subscription-screen',
  ENABLE_APPLE_PAY: 'enable-apple-pay',
  INAPP_CANCELLATION: 'inapp-cancellation-flow',
  PROFILES_SCREEN: 'customer-portal-profiles-screen',
  REFERRAL_REWARDS_SCREEN: 'customer-portal-referral-rewards-screen',
  REMOVE_PROFILE: 'customer-portal-remove-profile',
  RENEW_SUBSCRIPTION: 'customer-portal-renew-subscription',
  SHOW_MANAGE_BILLING: 'show-manage-billing',
  SHOW_REFERRAL_CODE: 'show-referral-code',
  USE_UPDATED_TRIAL_PICKER: 'use-updated-trial-picker',
  CUSTOMIZED_CANCELLATION_FLOW: 'customized-cancellation-flow',
  ENABLE_RESUSBCRIPTON_FLOW: 'enable-resubscription-flow',
  ENABLE_RESUSBCRIPTON_REDIRECT: 'enable-resubscription-redirect',
  ENABLE_IN_APP_REFERRALS: 'enable-in-app-referrals',
  PARENT_DASHBOARD_SCREEN: 'parent-dashboard-screen',
};
