/*
 * Simple screen that renders onboarding panels for preview.
 */

import {Query} from '@apollo/client/react/components';
import CircularProgress from '@mui/material/CircularProgress';
import withStyles from '@mui/styles/withStyles';
import React from 'react';
import OnboardingPanel from 'shared/components/OnboardingPanel';
import {StringParam, withQueryParams} from 'use-query-params';
import {trackPageWithIp} from '../../../shared/utils/analytics';
import {SlackMessageTypes, sendSlackMessage} from '../../../shared/utils/slack';
import query from './OnboardingScreen.query';
import styles from './OnboardingScreen.styles';

const queryParams = {
  confirmationId: StringParam,
  device: StringParam,
  flow: StringParam,
  onboardingResponseUid: StringParam,
};

class OnboardingScreen extends React.Component {
  constructor(props) {
    super(props);
    const isPostOnboarding =
      !!this.props.query.onboardingResponseUid ||
      !!this.props.query.confirmationId;

    const isInApp = this.props.query.device === 'mobile';

    const defaultWebFlowName = isPostOnboarding
      ? 'digitalpostsignup'
      : 'digitalello';

    const defaultInAppFlowName = isPostOnboarding
      ? 'digitalpostsignup'
      : 'inappdigital';

    const defaultFlowName = isInApp ? defaultInAppFlowName : defaultWebFlowName;

    this.state = {
      defaultFlowName,
      flowName: this.props.query.flow || this.props.flowName || defaultFlowName,
      hasError: false,
    };
  }

  componentDidMount() {
    trackPageWithIp('Ello Onboarding');
  }

  renderLoading() {
    const {classes} = this.props;

    return (
      <CircularProgress color="primary" className={classes.loadingIndicator} />
    );
  }

  handleError = error => {
    const message = `An error was encountered by a customer while loading the Onboarding Flow: ${this.state.flowName}\n\nDefaulting to default onboarding flow ${this.state.defaultFlowName}\n\nError: ${error.message} \nURL: ${window.location.href}.`;
    sendSlackMessage(SlackMessageTypes.OnboardingError, message);

    this.setState({
      flowName: this.state.defaultFlowName,
      hasError: true,
    });
  };

  renderError(error) {
    const message = `An error was encountered by a customer while loading the Onboarding Flow: ${this.state.flowName}\n\nError: ${error.message} \nURL: ${window.location.href}.`;
    sendSlackMessage(SlackMessageTypes.OnboardingError, message);
    return 'Error loading Onboarding Flow. Email us at support@ello.com or text us at (415) 214-8119.';
  }

  render() {
    const {flowName, hasError} = this.state;

    return (
      <Query query={query} variables={{flowName}}>
        {({data, loading, error}) => {
          if (loading) {
            return this.renderLoading();
          }
          if (error && !hasError) {
            this.handleError(error);
            return null;
          }
          if (error && hasError) {
            return this.renderError(error);
          }
          if (data) {
            return (
              <OnboardingPanel
                flowSpec={data.onboardingFlowPublic.content}
                flowName={flowName}
                maxHeight={this.props.maxHeight}
              />
            );
          }
          return null;
        }}
      </Query>
    );
  }
}

export default withStyles(styles)(
  withQueryParams(queryParams, OnboardingScreen),
);
