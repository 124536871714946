import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import {StringParam, useQueryParam} from 'use-query-params';
import useStyles from '../common/FormTextField/FormTextField.styles';
import {useStrings} from '../../utils/localization';
import {
  cleanPhoneInput,
  isValidPhoneNumber,
} from './InternationalPhone.service';

type CountryType = {
  code: string;
  label: string;
  phone: string;
  suggested?: boolean;
};

const defaultCountry = {
  code: 'US',
  label: 'United States',
  phone: '1',
};

const countries: CountryType[] = [
  {code: 'PH', label: 'Philippines', phone: '63'},
  defaultCountry,
];

type Props = {
  onValidationChanged: (isValid: boolean) => void;
};

export const InternationalPhone = ({onValidationChanged}: Props) => {
  const [countryCodeQuery, setCountryCodeQuery] = useQueryParam(
    'countryCode',
    StringParam,
  );
  const [phoneQuery, setPhoneQuery] = useQueryParam('phone', StringParam);
  const defaultSelected =
    countries.find(country => country.code === countryCodeQuery) ||
    defaultCountry;
  const [countryInput, setCountryInput] = React.useState<string>('');
  const [selectedCountry, setSelectedCountry] =
    React.useState<CountryType>(defaultSelected);
  const defaultPhone =
    phoneQuery?.replace(`+${selectedCountry.phone}`, '') || '';
  const [phoneInput, setPhoneInput] = React.useState<string>(defaultPhone);
  const [phoneError, setPhoneError] = React.useState<boolean>(false);
  const [showCode, setShowCode] = React.useState<boolean>(false);

  const classes = useStyles();
  const strings = useStrings();

  const onInput = (event: any) => {
    const input = event.target.value;
    setPhoneInput(input);
    const cleanInput = cleanPhoneInput(input);

    const isValidPhone = isValidPhoneNumber(cleanInput, selectedCountry.code);
    if (!isValidPhone) {
      onValidationChanged(false);
      return;
    }

    onValidationChanged(true);
    setPhoneError(false);
    const phoneWithCode = `+${selectedCountry.phone}${cleanInput}`;
    setPhoneQuery(phoneWithCode);
    window.analytics.identify({
      tel: phoneWithCode,
    });
  };

  const errorCheck = () => {
    setPhoneError(!isValidPhoneNumber(phoneInput, selectedCountry.code));
  };

  const onBlur = () => {
    setShowCode(false);
    errorCheck();
  };

  const onFocus = () => {
    setShowCode(true);
  };

  return (
    <Box className={classes.fieldContainer}>
      <Box>
        <Autocomplete
          options={countries}
          value={selectedCountry}
          inputValue={countryInput}
          getOptionLabel={option => `${option.label} (+${option.phone})`}
          onInputChange={(event, newInputValue) => {
            setCountryInput(newInputValue);
          }}
          isOptionEqualToValue={(option, value) => option.code === value.code}
          onChange={(event, newValue) => {
            if (newValue) {
              setSelectedCountry(newValue);
              setCountryCodeQuery(newValue.code);
              const cleanInput = cleanPhoneInput(phoneInput);
              setPhoneQuery(`+${newValue.phone}${cleanInput}`);
            }
          }}
          renderInput={params => {
            return (
              <TextField
                {...params}
                label="Country/Region"
                variant="outlined"
                className={classes.textField}
              />
            );
          }}
        />
      </Box>

      <Box className={classes.intlPhoneField}>
        <TextField
          variant="outlined"
          value={phoneInput}
          type="tel"
          label={phoneError ? strings.invalidPhone : strings.phoneNumber}
          error={phoneError}
          style={{width: '100%'}}
          className={classes.textField}
          InputProps={
            showCode
              ? {
                  startAdornment: <Box>+{selectedCountry.phone}</Box>,
                }
              : {}
          }
          onInput={onInput}
          onBlur={onBlur}
          onFocus={onFocus}
          onKeyDown={event => {
            if (event.key === 'Enter') {
              errorCheck();
              onInput(event);
            }
          }}
        />
      </Box>
    </Box>
  );
};
