/**
 * Like PaymentPanel, this is an onboarding flow submission panel, but this
 * one handles generic submissions.
 */

import {withApollo} from '@apollo/client/react/hoc';
import Alert from '@mui/material/Alert';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import withStyles from '@mui/styles/withStyles';
import React from 'react';
import {trackEventWithIp} from 'shared/utils/analytics';
import {LocalizationContext} from 'shared/utils/localization';
import {collectQueryParams} from 'shared/utils/queryParams';
import {CustomerProductType} from '../../utils/productType';
import {ONBOARD_DIGITAL_CUSTOMER_MUTATION} from '../PaymentPanel/PaymentPanel.query';
import {
  ONBOARDING_TRIAL_USER_MUTATION,
  PARTIAL_ONBOARDING_MUTATION,
} from './GenericSubmitPanel.query';
import {GenericSubmitPanelService} from './GenericSubmitPanel.service';
import styles from './GenericSubmitPanel.styles';

const ONBOARDING_ACTION = 'trial-onboarding';
const CLOSED_V2_BETA_ACTION = 'closed-v2-beta';
const closedV2Beta = 'closed-v2-beta';
const unconstrainedLevelProgression = 'unconstrained-level-progression';
class GenericSubmitPanel extends React.Component {
  state = {
    loading: false,
    error: null,
    errorVisible: false,
  };

  /**
   * Triggers API call
   */
  performAction = async () => {
    if (this.smsLink) {
      trackEventWithIp('Onboarding ComposeSMS');
      window.location.href = this.smsLink;
      return;
    }

    // Allow for tracking of a custom submit event
    const event = this.props.customAnalyticsEvent || 'Onboarding Booking';
    trackEventWithIp(event);

    this.setState({loading: true});

    // Capture user UID
    // (bubble-wrap for ad blocker)
    let anonymousId;
    try {
      anonymousId = window.analytics.user().anonymousId();
    } catch (e) {
      console.warn(e);
    }

    // Capture all collected params
    const params = collectQueryParams();
    params.uid = anonymousId;

    // Capture locale
    params.locale = this.locale;

    const onboardedPartialAccount =
      await GenericSubmitPanelService.onboardPartialAccount(
        this.props,
        params,
        this.handleStateChange,
      );
    if (onboardedPartialAccount) {
      return;
    }

    const isClosedV2Beta = this.props.action === CLOSED_V2_BETA_ACTION;
    if (isClosedV2Beta) {
      const variables = {
        name: params.name,
        email: params.email,
        phone: params.phone,
        content: params,
        flowName: this.props.flowName,
        stripePriceId: this.props.stripePriceId,
        tags: [closedV2Beta, unconstrainedLevelProgression],
        uid: params.uid,
      };
      this.props.client
        .mutate({
          mutation: ONBOARD_DIGITAL_CUSTOMER_MUTATION,
          variables,
        })
        .catch(error => {
          this.setState({loading: false});
          this.setState({
            error: 'An error occured. Text us at (415) 214-8119.',
            errorVisible: true,
          });
        })
        .then(onboardingResult => {
          if (onboardingResult) {
            this.props.onConfirm(onboardingResult.data.onboardDigitalCustomer, {
              email: params.email,
              phone: params.phone,
              productType: CustomerProductType.digitalSubscription,
            });
          } else {
            this.setState({
              error:
                'There was an error creating your account. Text us at (415) 214-8119.',
              errorVisible: true,
            });
          }
          this.setState({loading: false});
        });

      return;
    }

    const isTrialUser = this.props.action === ONBOARDING_ACTION;
    if (isTrialUser) {
      this.props.client
        .mutate({
          mutation: ONBOARDING_TRIAL_USER_MUTATION,
          variables: {
            flowName: this.props.flowName,
            phone: params.phone,
            paymentMethod: 'none', // we just captured with stripe
            content: params, // may want to remove the ones we're already sending so we don't double
            name: params.name,
            email: params.email,
            uid: params.uid,
          },
        })
        .catch(error => {
          this.setState({loading: false});
          this.setState({
            error: 'An error occured. Text us at (415) 214-8119.',
            errorVisible: true,
          });
        })
        .then(onboardingResult => {
          if (onboardingResult) {
            this.props.onConfirm(onboardingResult.data.onboardingTrialUser, {
              phone: params.phone,
              email: params.email,
              endpoint: this.props.endpoint,
            });
          } else {
            this.setState({
              error:
                'There was an error creating your account. Text us at (415) 214-8119.',
              errorVisible: true,
            });
          }
          this.setState({loading: false});
        });
    } else {
      const {onboardingResponseUid, ...content} = params;
      this.props.client
        .mutate({
          mutation: PARTIAL_ONBOARDING_MUTATION,
          variables: {
            content,
            flowName: this.props.flowName,
            onboardingResponseUid,
          },
        })
        .catch(error => {
          this.setState({loading: false});
          this.setState({
            error: 'An error occured. Text us at (415) 214-8119.',
            errorVisible: true,
          });
        })
        .then(onboardingResult => {
          if (onboardingResult) {
            this.props.onConfirm(onboardingResult.data.partialOnboarding, {
              phone: params.phone,
              email: params.email,
              endpoint: this.props.endpoint,
            });
          } else {
            this.setState({
              error:
                'There was an error submitting your responses. Text us at (415) 214-8119.',
              errorVisible: true,
            });
          }
          this.setState({loading: false});
        });
    }
  };

  handleErrorClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({errorVisible: false});
  };

  handleStateChange = state => {
    this.setState(state);
  };

  render() {
    const {classes} = this.props;

    return (
      <LocalizationContext.Consumer>
        {({locale}) => {
          // Keep track at the instance level
          this.locale = locale;
          return (
            <>
              <Backdrop className={classes.backdrop} open={this.state.loading}>
                <CircularProgress color="primary" />
              </Backdrop>

              <Snackbar
                open={this.state.errorVisible}
                onClose={this.handleErrorClose}
                autoHideDuration={3000}>
                <Alert onClose={this.handleErrorClose} severity="error">
                  {this.state.error}
                </Alert>
              </Snackbar>
            </>
          );
        }}
      </LocalizationContext.Consumer>
    );
  }
}

export default withStyles(styles)(
  withApollo(GenericSubmitPanel, {withRef: true}),
);
