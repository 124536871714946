import {useQuery} from '@apollo/client';
import {
  curriculumProgressReportQuery,
  lifeTimeAchievementsReportQuery,
  profileQuestsByIDQuery,
  readingRecordQuery,
  weeklyAchievementsReportQuery,
} from './ProgressReportScreen.query';
import {
  CurriculumProgress,
  LifeTimeAchievementsReport,
  ProfileCharacter,
  QueryResult,
  Quest,
  ReadingRecordsList,
  WeeklyAchievement,
} from './types';
import {ReactComponent as Bull} from 'shared/assets/profileCharacters/bullCircular.svg';
import {ReactComponent as Cat} from 'shared/assets/profileCharacters/catCircular.svg';
import {ReactComponent as Robot} from 'shared/assets/profileCharacters/robotCircular.svg';
import {ReactComponent as DinoBird} from 'shared/assets/profileCharacters/dinoBirdCircular.svg';
import {ReactComponent as Bird} from 'shared/assets/profileCharacters/birdCircular.svg';
import {ReactComponent as Unicorn} from 'shared/assets/profileCharacters/unicornCircular.svg';

export function useLifeTimeAchievementsReport(
  profileId: string,
): QueryResult<LifeTimeAchievementsReport> {
  const {data, error, loading} = useQuery(lifeTimeAchievementsReportQuery, {
    variables: {profileId},
    skip: !profileId,
  });

  const achievementsReport = data?.lifeTimeAchievementsReport ?? null;

  return {data: achievementsReport, error, loading};
}

export function useWeeklyAchievementsReport(
  profileId: string,
  hasQuests: boolean,
): QueryResult<WeeklyAchievement> {
  const {data, error, loading} = useQuery(weeklyAchievementsReportQuery, {
    variables: {profileId},
    skip: !hasQuests,
  });

  const weeklyAchievements = data?.weeklyAchievementsReport ?? null;

  return {data: weeklyAchievements, error, loading};
}

export function useReadingRecord(
  profileId: string,
  hasQuests: boolean,
): QueryResult<ReadingRecordsList[]> {
  const {data, error, loading} = useQuery(readingRecordQuery, {
    variables: {profileId},
    skip: !profileId && !hasQuests,
  });

  const readingRecords = data?.readingRecord ?? null;

  return {data: readingRecords, error, loading};
}

export function useCurriculumProgressReport(
  profileId: string,
  hasQuests: boolean,
): QueryResult<CurriculumProgress> {
  const {data, error, loading} = useQuery(curriculumProgressReportQuery, {
    variables: {profileId},
    skip: !hasQuests,
  });

  return {
    data: data?.curriculumProgressReport ?? null,
    error,
    loading,
  };
}

export function useProfileQuestsByID(
  profileId: string,
  filter?: {status?: string},
): QueryResult<Quest[]> {
  const {data, error, loading} = useQuery(profileQuestsByIDQuery, {
    variables: {profileId, filter},
    skip: !profileId,
  });

  return {
    data: data?.profileQuestsByID.quests ?? null,
    error,
    loading,
  };
}

const profileCharacterMapping: Record<ProfileCharacter, React.FC> = {
  [ProfileCharacter.Bull]: Bull,
  [ProfileCharacter.Cat]: Cat,
  [ProfileCharacter.Robot]: Robot,
  [ProfileCharacter.DinoBird]: DinoBird,
  [ProfileCharacter.Bird]: Bird,
  [ProfileCharacter.Unicorn]: Unicorn,
};

export const getProfileCharacter = (profileCharacter?: ProfileCharacter) => {
  const Character =
    profileCharacterMapping[profileCharacter ?? ProfileCharacter.Bull];

  return Character;
};

export const getReadingStatsCopy = (
  profileName: string,
  gradeRole: string,
  booksRead: number,
) => {
  if (booksRead < 5) {
    return `Let’s help ${profileName} catch up to the pace of most ${gradeRole}s. Encourage them to complete at least one quest each week to stay on track. To accelerate their learning, aim for two or more quests per week!`;
  }

  if (booksRead < 10) {
    return `Great job, ${profileName}! They’re keeping pace with most ${gradeRole}s. To continue progressing steadily, encourage them to complete at least one quest per week. For faster growth, aim for two or more quests per week!`;
  }

  return `Wow, ${profileName} is on fire! They’re learning faster than most ${gradeRole}s. Encourage them to keep up the fantastic work by completing two or more quests per week to maintain this amazing momentum!`;
};

export const getReadingLevelContent = (
  profileName: string,
  data: CurriculumProgress,
): string => {
  const {
    gradeName,
    remainingQuests,
    subGrade,
    nextSubGrade,
    progress,
    monthEquivalent,
  } = data;

  if (!nextSubGrade) {
    return progress < 100
      ? `${profileName} is reading at the level of a child in the ${monthEquivalent} month of ${gradeName}. They are just ${remainingQuests} reading quests away from completing our ${subGrade} curriculum.`
      : `Congrats! ${profileName} has finished the ${subGrade} curriculum!`;
  }

  return progress === 100
    ? `Congrats! ${profileName} has finished the ${subGrade} curriculum! Complete the next quest to advance to the ${nextSubGrade} curriculum.`
    : `${profileName} is reading at the level of a child in the ${monthEquivalent} month of ${gradeName}. They are just ${remainingQuests} reading quests away from completing our ${subGrade} curriculum and moving on to the ${nextSubGrade} curriculum.`;
};

export function interpolateLessonDescription(
  profileName: string,
  text?: string,
) {
  if (!text) {
    return '';
  }

  return text.replace(/%CHILD_NAME%/g, profileName);
}

export function formatNumber(number: number): string {
  if (number < 1000) return number.toString();
  if (number < 10000) return number.toLocaleString();
  return `${Math.floor(number / 1000)}K`;
}
