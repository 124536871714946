import React from 'react';
import {Typography} from '@mui/material';
import {gray900} from 'shared/styles/palette';

export const StatsLabel: React.FC = ({children}) => (
  <Typography
    variant="body1"
    sx={{
      textAlign: 'center',
      fontSize: '16px',
      fontWeight: '700',
      lineHeight: '1.6',
      letterSpacing: '-0.048px',
      color: gray900,
    }}>
    {children}
  </Typography>
);
