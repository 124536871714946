import React from 'react';
import {ConfirmationDialog} from './ConfirmationDialog';
import {Typography} from '@mui/material';
import {gray900} from 'shared/styles/palette';
import {BillingSource} from '../../../types/customer';

type Props = {
  open: boolean;
  billingSource?: string;
  onClose: () => void;
  onClick: () => void;
};

export const CustomerExistsDialog: React.FC<Props> = ({
  open,
  billingSource,
  onClose,
  onClick,
}) => {
  const isStripeCustomer = billingSource === BillingSource.Stripe;
  const text = isStripeCustomer ? (
    'Looks like you already have an Ello account! Log in to renew your subscription.'
  ) : (
    <>
      Welcome back! It seems you already have an account. To renew your
      subscription, go to{' '}
      <strong>Settings &gt; [Your Name] &gt; Subscriptions </strong>
      on your device
    </>
  );
  return (
    <ConfirmationDialog
      open={open}
      title=""
      okText={isStripeCustomer ? 'Log in Now →' : 'Go to Settings →'}
      onClose={onClose}
      onClick={onClick}>
      <Typography
        variant="body1"
        textAlign="center"
        mb="10px"
        color={gray900}
        fontSize="20px">
        {text}
      </Typography>
    </ConfirmationDialog>
  );
};
