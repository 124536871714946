export type CountryType = {
  code: string;
  label: string;
  phone: string;
  suggested?: boolean;
};

export const defaultCountry = {
  code: 'US',
  label: 'United States',
  phone: '1',
};

export const countries: CountryType[] = [
  {code: 'PH', label: 'Philippines', phone: '63'},
  defaultCountry,
];

export function phoneNumberRegex(country: CountryType) {
  return /^\d{10}$/;
}
