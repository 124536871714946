import React, {useState} from 'react';
import {Box, Collapse, useTheme} from '@mui/material';
import {Profile} from 'shared/types/profile';
import {ProfileDropdownHeader} from './ProfileDropdown.header';
import {ProfileDropdownList} from './ProfileDropdown.list';
import {TABLET_BREAKPOINT} from 'shared/styles/breakpoints';
import {white} from 'shared/styles/palette';

type Props = {
  profiles: Profile[] | undefined;
  selectedProfile: Profile | null;
  setSelectedProfile: (profile: Profile) => void;
};

export const ProfileDropdown: React.FC<Props> = ({
  profiles = [],
  selectedProfile,
  setSelectedProfile,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const theme = useTheme();

  const hasMultipleProfiles = profiles.length > 1;
  const toggleDropdown = () => {
    if (hasMultipleProfiles) {
      setIsOpen(prev => !prev);
    }
  };

  const handleSelect = (profile: Profile) => {
    setSelectedProfile(profile);
    setIsOpen(false);
  };

  return (
    <Box
      sx={{
        position: 'relative',
        marginBottom: '16px',
        [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
          width: 'calc(100% + 48px)',
          marginLeft: '-24px',
        },
      }}>
      <ProfileDropdownHeader
        selectedProfile={selectedProfile}
        isOpen={isOpen}
        hasMultipleProfiles={hasMultipleProfiles}
        toggleDropdown={toggleDropdown}
      />
      <Collapse
        sx={{
          position: 'absolute',
          left: 0,
          width: '100%',
          backgroundColor: white,
          padding: '0 24px 12px 24px',
          boxShadow: '0px 8px 20px 0px rgba(0, 0, 0, 0.05)',
        }}
        in={isOpen}>
        <ProfileDropdownList
          profiles={profiles}
          selectedProfile={selectedProfile}
          handleSelect={handleSelect}
        />
      </Collapse>
    </Box>
  );
};
