import React from 'react';
import {ReactComponent as ReadingIcon} from 'shared/assets/icons/ReadingIcon.svg';
import {ProgressCard} from '../components/ProgressCard/ProgressCard';
import {SkeletonRows} from '../components/SkeletonRows';
import {Box} from '@mui/material';
import {Stats} from '../components/Stats';

export const ReadingStatsEmpty = () => {
  const statsData = [
    {statValue: 0, statLabel: 'Books'},
    {statValue: 0, statLabel: 'Minutes'},
    {statValue: 0, statLabel: 'Words'},
  ];

  return (
    <ProgressCard icon={<ReadingIcon />} title="This Week's Reading" isEmpty>
      <Box sx={{mb: '20px'}}>
        <Stats stats={statsData} />
      </Box>
      <SkeletonRows count={2} />
    </ProgressCard>
  );
};
