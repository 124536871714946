import React from 'react';
import {Banner} from './Banner';
import {ReactComponent as QuestImage} from 'shared/assets/QuestImage.svg';

type Props = {
  profileName: string;
};

export const NoQuestsBanner: React.FC<Props> = ({profileName}) => {
  return (
    <Banner
      image={<QuestImage />}
      body={
        <>
          {profileName} hasn’t completed their first quest yet.
          <br /> To unlock their progress report, have {profileName} complete
          their first quest.
        </>
      }
    />
  );
};
