import React from 'react';
import {ProgressCard} from '../components/ProgressCard/ProgressCard';
import {ReactComponent as TrickyWordsIcon} from 'shared/assets/icons/TrickyWordsIcon.svg';
import {SkeletonRows} from '../components/SkeletonRows';

type Props = {
  profileName: string;
};

export const TrickyWordsEmpty: React.FC<Props> = ({profileName}) => {
  return (
    <ProgressCard
      icon={<TrickyWordsIcon />}
      title={`Words ${profileName} Found Tricky`}
      isEmpty>
      <SkeletonRows />
    </ProgressCard>
  );
};
