import React from 'react';
import {SxProps, Theme, Typography} from '@mui/material';
import {gray700, teal500} from 'shared/styles/palette';

type Props = {
  sx?: SxProps<Theme>;
};

export const PrimaryTypography: React.FC<Props> = ({sx, children}) => (
  <Typography
    variant="body1"
    sx={{
      color: teal500,
      fontWeight: '700',
      lineHeight: '1.6',
      letterSpacing: '-0.048px',
      ...sx,
    }}>
    {children}
  </Typography>
);

export const SecondaryTypography: React.FC<Props> = ({sx, children}) => (
  <Typography
    variant="body2"
    sx={{
      fontSize: '13px',
      color: gray700,
      lineHeight: '1.6',
      letterSpacing: '-0.039px',
      ...sx,
    }}>
    {children}
  </Typography>
);
