import React from 'react';
import {Box} from '@mui/material';
import {StatsLabel} from './StatsLabel';
import {ApolloError} from '@apollo/client';
import {Loader} from '../components/Loader';
import {PrimaryTypography} from './Typography';

type Props = {
  readingLevel?: string;
  loading: boolean;
  error: ApolloError | undefined;
};

export const ReadingLevelInfo: React.FC<Props> = ({
  readingLevel,
  loading,
  error,
}) => {
  const content = (() => {
    if (loading) return <Loader />;
    if (error) return <PrimaryTypography>-</PrimaryTypography>;
    return <PrimaryTypography>{readingLevel}</PrimaryTypography>;
  })();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '8px',
        width: '120px',
      }}>
      {content}
      <StatsLabel>Reading Level</StatsLabel>
    </Box>
  );
};
