import React from 'react';
import {Typography} from '@mui/material';
import {blue500} from 'shared/styles/palette';
import {CardBodyTypography} from '../components/ProgressCard/CardBodyTypography';
import {Loader} from '../components/Loader';
import {QueryResult, WeeklyAchievement} from '../types';

type Props = {
  profileName: string;
  weeklyAchievements: QueryResult<WeeklyAchievement>;
};

export const TrickyWordsContent: React.FC<Props> = ({
  profileName,
  weeklyAchievements,
}) => {
  const {data, error, loading} = weeklyAchievements;

  if (loading) return <Loader />;
  if (error) return null;

  const trickyWords = data?.trickyWords;
  const hasTrickyWords = Boolean(trickyWords?.length);

  return (
    <>
      {hasTrickyWords ? (
        <Typography
          sx={{
            fontSize: '26px',
            fontWeight: 800,
            color: blue500,
            textAlign: 'center',
            lineHeight: '1.4',
            letterSpacing: '-0.104px',
          }}>
          {trickyWords?.join(', ')}
        </Typography>
      ) : (
        <CardBodyTypography>
          {profileName} didn’t ask for any help! Watch them read their next
          book. If {profileName} isn’t being challenged, we can help you adjust
          their level.
        </CardBodyTypography>
      )}
    </>
  );
};
