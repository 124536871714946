import {Stack} from '@mui/material';
import React from 'react';

import {StatsLabel} from './StatsLabel';
import {ApolloError} from '@apollo/client';
import {ProgressStatsContent} from './ProgressStats.content';

type Props = {
  progress: number;
  loading: boolean;
  error: ApolloError | undefined;
};

export const ProgressStats: React.FC<Props> = ({progress, loading, error}) => {
  return (
    <Stack
      sx={{
        flexGrow: 1,
        gap: '8px',
        alignItems: 'center',
      }}>
      <ProgressStatsContent
        progress={progress}
        loading={loading}
        error={error}
      />
      <StatsLabel>Curriculum Progress</StatsLabel>
    </Stack>
  );
};
