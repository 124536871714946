/*
 * Generic confirmation panel.
 */

import {withApollo} from '@apollo/client/react/hoc';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import React from 'react';
import {trackEventWithIp, trackPageWithIp} from 'shared/utils/analytics';
import {collectQueryParams} from 'shared/utils/queryParams';
import mutation from './GenericConfirmPanel.query';

class GenericConfirmPanel extends React.Component {
  state = {
    error: null,
    errorVisible: false,
  };

  componentDidMount() {
    trackPageWithIp('Onboarding Confirmation');
  }

  /**
   * Handle confirmation page submission
   */
  performAction = async () => {
    trackEventWithIp('Onboarding Post-Confirmation');
    // Capture all collected params
    const params = collectQueryParams();

    try {
      await this.props.client.mutate({
        mutation,
        variables: {
          confirmationId: params.confirmationId,
          content: params,
        },
      });
    } catch (error) {
      this.setState({
        error:
          'We were unable to update your response. Text us at (415) 214-8119.',
        errorVisible: true,
      });
      return false;
    }
    return true;
  };

  handleErrorClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({errorVisible: false});
  };

  render() {
    return (
      <Snackbar
        open={this.state.errorVisible}
        onClose={this.handleErrorClose}
        autoHideDuration={3000}>
        <Alert onClose={this.handleErrorClose} severity="error">
          {this.state.error}
        </Alert>
      </Snackbar>
    );
  }
}

export default withApollo(GenericConfirmPanel, {withRef: true});
