import React from 'react';
import {ReactComponent as RecentBooksIcon} from 'shared/assets/icons/RecentBooksIcon.svg';
import {ProgressCard} from '../components/ProgressCard/ProgressCard';
import {SkeletonRows} from '../components/SkeletonRows';

export const ReadingRecordEmpty = () => {
  return (
    <ProgressCard icon={<RecentBooksIcon />} title="Reading Record" isEmpty>
      <SkeletonRows count={3} />
    </ProgressCard>
  );
};
