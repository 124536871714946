import React, {useState} from 'react';
import Section, {SectionActionButton} from 'customerPortal/components/Section';
import DataList from 'customerPortal/components/DataList/DataList';
import {QUERY_ACCOUNT_DETAILS} from './AccountDetails.query';
import {useQuery} from '@apollo/react-hooks';
import {Customer, User} from 'shared/types/customer';
import DeleteAccountForm from './DeleteAccount/DeleteAccountForm';
import featureFlags, {FEATURE_IDS} from 'shared/utils/featureFlags';

const FRIENDLY_KEYS: Record<keyof User, string> = {
  name: 'Parent Name',
  email: 'Email',
  phoneNumber: 'Phone Number',
  countryCode: '',
};

const getDetailsMapping = (userDetails: User): Record<string, string> => {
  return Object.entries(userDetails).reduce(
    (res: Record<string, string>, current: [string, string | null]) => {
      const friendlyKey = FRIENDLY_KEYS[current[0] as keyof User];

      if (!friendlyKey) return res;

      res[friendlyKey] = current[1] ?? '---';
      return res;
    },
    {},
  );
};

const AccountDetailsScreen = () => {
  const [showDeleteAccount, setShowDeleteAccount] = useState(false);
  const {loading, data, error} = useQuery<{customer: Customer}>(
    QUERY_ACCOUNT_DETAILS,
    {
      onError: error => console.error('Account Details::', error),
    },
  );

  const showDeleteAccountButton = featureFlags.isOn(
    FEATURE_IDS.ALLOW_DELETE_ACCOUNT,
  );

  const closeDeleteAccountDialog = () => {
    setShowDeleteAccount(false);
  };

  const openDeleteAccountDialog = () => () => {
    setShowDeleteAccount(true);
  };

  return (
    <>
      <Section
        title="Account Details"
        loading={loading}
        subtitle={
          showDeleteAccountButton && (
            <SectionActionButton
              danger={true}
              label="Delete Account"
              onClick={openDeleteAccountDialog()}
            />
          )
        }
        error={error ? 'Unable to load account details' : ''}>
        <>
          {data?.customer.userDetails && (
            <DataList data={getDetailsMapping(data.customer.userDetails)} />
          )}
        </>
      </Section>
      <DeleteAccountForm
        open={showDeleteAccount}
        onClose={closeDeleteAccountDialog}
        email={data?.customer.userDetails.email ?? ''}
      />
    </>
  );
};

export default AccountDetailsScreen;
