import React from 'react';
import {Box, Typography} from '@mui/material';
import {gray900, turquoise300} from 'shared/styles/palette';

type Props = {
  image: React.ReactNode;
  body: React.ReactNode;
};

export const Banner: React.FC<Props> = ({image, body}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '16px',
        borderRadius: '16px',
        padding: '24px 16px',
        width: '100%',
        maxWidth: '380px',
        backgroundColor: turquoise300,
      }}>
      {image}
      <Typography
        variant="body1"
        sx={{fontWeight: '700', textAlign: 'center', color: gray900}}>
        {body}
      </Typography>
    </Box>
  );
};
