import React from 'react';
import {Box} from '@mui/material';
import {ReactComponent as ReadingLevelIcon} from 'shared/assets/icons/ReadingLevelIcon.svg';
import {ProgressCard} from '../components/ProgressCard/ProgressCard';
import {SkeletonRows} from '../components/SkeletonRows';
import {PrimaryTypography} from './Typography';
import {CardTitle} from '../components/ProgressCard/CardTitle';
import {ProgressBar} from './ProgressBar';

type Props = {
  profileName: string;
};

export const ReadingLevelEmpty: React.FC<Props> = ({profileName}) => {
  return (
    <ProgressCard
      icon={<ReadingLevelIcon />}
      title={`${profileName}'s Reading Level`}
      isEmpty>
      <Box
        sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <PrimaryTypography sx={{mb: '20px'}}>?</PrimaryTypography>
        <CardTitle>Curriculum Progress</CardTitle>
        <Box mb="20px" width="100%">
          <PrimaryTypography sx={{textAlign: 'center', mb: '8px'}}>
            0%
          </PrimaryTypography>
          <Box sx={{px: '16px'}}>
            <ProgressBar progress={0} />
          </Box>
        </Box>
        <SkeletonRows count={2} />
      </Box>
    </ProgressCard>
  );
};
