import React from 'react';
import {ListItemButton, ListItemText} from '@mui/material';
import {blue500} from 'shared/styles/palette';
import {Profile} from 'shared/types/profile';
import {getProfileCharacter} from '../../ProgressReportScreen.service';
import {ProfileCharacter} from '../../types';

type Props = {
  profile: Profile;
  handleSelect: (profile: Profile) => void;
};

export const ProfileListItem: React.FC<Props> = ({profile, handleSelect}) => {
  const ProfileCharacterImage = getProfileCharacter(
    profile.profileCharacter as ProfileCharacter,
  );

  return (
    <ListItemButton
      onClick={() => handleSelect(profile)}
      sx={{
        display: 'flex',
        alignItems: 'center',
        padding: 0,
        gap: '16px',
        py: '16px',
      }}>
      <ProfileCharacterImage />
      <ListItemText
        primary={profile.name}
        primaryTypographyProps={{
          fontSize: '22px',
          fontWeight: '700',
          textTransform: 'capitalize',
          lineHeight: '1.4',
          letterSpacing: '-0.176px',
          color: blue500,
        }}
      />
    </ListItemButton>
  );
};
