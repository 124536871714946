import {Box} from '@mui/material';
import React from 'react';
import {StatsItem} from './StatsItem';

type Props = {
  stats: {
    statValue: string | number;
    statLabel: string;
  }[];
};

export const Stats: React.FC<Props> = ({stats}) => {
  return (
    <Box sx={{display: 'flex', gap: '16px', maxWidth: '100%'}}>
      {stats.map((stat, index) => (
        <StatsItem
          key={index}
          statValue={stat.statValue}
          statLabel={stat.statLabel}
        />
      ))}
    </Box>
  );
};
