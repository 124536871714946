import React from 'react';
import {LinearProgress} from '@mui/material';
import {turquoise900} from 'shared/styles/palette';

type Props = {
  progress?: number;
};

export const ProgressBar: React.FC<Props> = ({progress}) => {
  return (
    <LinearProgress
      variant="determinate"
      value={progress}
      sx={{
        width: '100%',
        height: '12px',
        borderRadius: '20px',
        backgroundColor: 'turqoise50',
        '& .MuiLinearProgress-bar': {
          borderRadius: '20px',
          backgroundColor: turquoise900,
        },
      }}
    />
  );
};
