import React, {useEffect, useState} from 'react';
import {Box, CircularProgress, useMediaQuery, useTheme} from '@mui/material';
import {OverallStats} from './OverallStats/OverallStats';
import {MobileDashboard} from './MobileDashboard/MobileDashboard';
import {DesktopDashboard} from './DesktopDashboard/DesktopDashboard';
import {ErrorCard} from './components/ErrorCard';
import {
  useCurriculumProgressReport,
  useLifeTimeAchievementsReport,
  useProfileQuestsByID,
  useReadingRecord,
  useWeeklyAchievementsReport,
} from './ProgressReportScreen.service';
import {EmptyState} from './EmptyState/EmptyState';
import {useProfiles} from '../ProfilesScreen/EditProfile/EditProfileScreen.service';
import {Profile} from 'shared/types/profile';
import Alert from 'shared/components/common/Alert/Alert';
import {ProfileDropdown} from './components/ProfileDropdown/ProfileDropdown';
import {SupportContact} from './components/SupportContact';
import {trackEventWithIp} from 'shared/utils/analytics';

export const ProgressReportScreen = () => {
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('lg'));
  const [selectedProfile, setSelectedProfile] = useState<Profile | null>(null);

  const {
    data: profilesData,
    loading: profilesLoading,
    error: profilesError,
  } = useProfiles();

  const profiles = profilesData?.profiles;

  useEffect(() => {
    trackEventWithIp('ParentDashboard DashboardViewed');
    if (profiles?.length && !selectedProfile) {
      setSelectedProfile(profiles[0]);
    }
  }, [profiles, selectedProfile]);

  const {id: profileId = '', name: profileName = ''} = selectedProfile || {};

  const {
    data,
    loading: questsLoading,
    error: questsError,
  } = useProfileQuestsByID(profileId, {
    status: 'completed',
  });

  const hasQuests = !!data?.length;

  const lifeTimeAchievements = useLifeTimeAchievementsReport(profileId);

  const weeklyAchievements = useWeeklyAchievementsReport(profileId, hasQuests);

  const readingRecords = useReadingRecord(profileId, hasQuests);

  const curriculumProgress = useCurriculumProgressReport(profileId, hasQuests);

  const hasError =
    lifeTimeAchievements.error ||
    weeklyAchievements.error ||
    readingRecords.error ||
    curriculumProgress.error;

  const fetchError = profilesError || questsError || lifeTimeAchievements.error;

  const isLoading =
    profilesLoading || questsLoading || lifeTimeAchievements.loading;

  const booksRead = lifeTimeAchievements?.data?.books ?? 0;
  const showEmptyState = !hasQuests || booksRead === 0;

  if (isLoading) {
    return <CircularProgress sx={{margin: '50px auto', display: 'block'}} />;
  }

  if (fetchError) {
    return (
      <Alert
        error={fetchError?.message || 'Error loading data'}
        autoHide={false}
      />
    );
  }

  return (
    <Box
      sx={{
        paddingBottom: '80px',
      }}>
      <ProfileDropdown
        profiles={profiles}
        selectedProfile={selectedProfile}
        setSelectedProfile={setSelectedProfile}
      />
      {showEmptyState ? (
        <EmptyState
          isMobileView={isMobileView}
          profileName={profileName}
          hasQuests={hasQuests}
        />
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}>
          {hasError && (
            <Box sx={{marginBottom: '16px'}}>
              <ErrorCard />
            </Box>
          )}
          <OverallStats lifeTimeAchievements={lifeTimeAchievements} />
          {isMobileView ? (
            <MobileDashboard
              profileName={profileName}
              profileId={profileId}
              weeklyAchievements={weeklyAchievements}
              readingRecords={readingRecords}
              curriculumProgress={curriculumProgress}
            />
          ) : (
            <DesktopDashboard
              profileName={profileName}
              profileId={profileId}
              weeklyAchievements={weeklyAchievements}
              readingRecords={readingRecords}
              curriculumProgress={curriculumProgress}
            />
          )}
        </Box>
      )}
      {isMobileView && <SupportContact />}
    </Box>
  );
};
