import React from 'react';
import {Box, List} from '@mui/material';
import ReadingRecordItem from './ReadingRecordItem';
import {BookStatus, ReadingRecordsList} from '../types';
import moment from 'moment';
import {CardBodyTypography} from '../components/ProgressCard/CardBodyTypography';
import {LinkButton} from './LinkButton';

type Props = {
  profileName: string;
  profileId: string;
  readingRecords: ReadingRecordsList[] | null;
};

const ReadingRecordList: React.FC<Props> = ({
  readingRecords,
  profileName,
  profileId,
}) => {
  const hasReadingRecords = !!readingRecords?.length;

  return (
    <Box>
      {hasReadingRecords ? (
        <>
          <List
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: '16px',
              padding: 0,
              marginBottom: '20px',
            }}>
            {readingRecords?.slice(0, 3).map((record, index) => (
              <ReadingRecordItem
                key={index}
                bookTitle={record.bookTitle}
                status={record.event as BookStatus}
                duration={`${record.minutes} Min`}
                date={moment(record.timestamp).format('MMM DD')}
                image={record.coverPhoto}
              />
            ))}
          </List>
          <LinkButton
            to={`/account/progress-report/reading-records/${profileId}`}>
            View all
          </LinkButton>
        </>
      ) : (
        <CardBodyTypography>
          {profileName} hasn’t read in a while. Once they do, you’ll see all of
          the books they read here.
        </CardBodyTypography>
      )}
    </Box>
  );
};

export default ReadingRecordList;
